import React from "react"
import Button from "../../atoms/Button/Button.component"
import classNames from "classnames"
import Image from "../../atoms/Image/Image.atom"

// Types
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import { ButtonProps } from "../../../interfaces/Button.interface"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"

// SVG
import Calendar from "../../../assets/svgs/Calendar.svg"
import { BorderIconsProps } from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface PrimaryCardProps extends BorderIconsProps {
  image: StoryblokAsset
  heading: string
  date: string
  body_text: string
  primary_cta: ButtonProps[]
  secondary_cta: ButtonProps[]
  hasSiblingCard: boolean
}

const PrimaryCard: React.FC<PrimaryCardProps> = ({
  image,
  heading,
  date,
  body_text,
  primary_cta,
  secondary_cta,
  hasSiblingCard,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const ContainerClassNames = classNames({
    "md:flex md:items-stretch": !hasSiblingCard,
    "md:flex md:items-stretch lg:block": hasSiblingCard,
  })

  const ImageWrapperClassNames = classNames({
    "md:mr-6 lg:mr-0 md:min-w-[260px] lg:w-auto aspect-[16/9] md:aspect-[3/4] lg:aspect-[16/9] -mt-12 md:-mt-0 lg:-mt-16 pb-6 md:pb-0 lg:pb-8 drop-shadow-2xl md:drop-shadow-none lg:drop-shadow-2xl":
      hasSiblingCard,
    "md:mr-6 lg:mr-8 xl:mr-10 2xl:mr-16 md:min-w-[260px] lg:min-w-[340px] aspect-[16/9] md:aspect-[3/4] lg:aspect-square -mt-12 md:-mt-0 pb-6 md:pb-0 drop-shadow-2xl md:drop-shadow-none":
      !hasSiblingCard,
  })

  const ImageClassNames = classNames("h-full overflow-hidden", {
    "rounded-6xl": hasSiblingCard,
    "rounded-6xl md:rounded-2xl": !hasSiblingCard,
  })

  return (
    <div
      className={classNames("bg-Primary-900 text-White", {
        "rounded-6xl h-full": hasSiblingCard,
        "rounded-2xl": !hasSiblingCard,
      })}
    >
      <BorderIconsFactory
        type={BorderIconType.Card}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="px-4 pt-4 pb-10 md:p-6 lg:p-8 2xl:p-10">
          <div className={ContainerClassNames}>
            <div className={ImageWrapperClassNames}>
              <div className={ImageClassNames}>
                {image && <Image image={image} objectFit="cover" />}
              </div>
            </div>
            <div>
              {heading && (
                <p className="text-xl lg:text-2xl 2xl:text-3xl font-extrabold pb-3">
                  {heading}
                </p>
              )}
              {date && (
                <div className="flex items-center space-x-2 pb-6">
                  <Calendar className="text-SP1" />
                  <p className="text-base font-semibold">{date}</p>
                </div>
              )}
              {heading && <div className="border-b-2 border-dashed mb-6"></div>}
              <p className="text-base pb-8">{body_text}</p>
              {!!primary_cta.length &&
                primary_cta[0].label &&
                primary_cta[0].link?.cached_url && (
                  <Button
                    link={primary_cta[0].link}
                    label={primary_cta[0].label}
                    type={ButtonTypes.PRIMARY}
                    size={ButtonSizeTypes.LG}
                    className="w-full md:w-auto"
                  ></Button>
                )}
              {secondary_cta &&
                !!secondary_cta.length &&
                secondary_cta[0].label &&
                secondary_cta[0].link?.cached_url && (
                  <Button
                    link={secondary_cta[0].link}
                    label={secondary_cta[0].label}
                    type={ButtonTypes.SECONDARY}
                    size={ButtonSizeTypes.LG}
                    className="hidden md:inline-block"
                  ></Button>
                )}
            </div>
          </div>
        </div>
      </BorderIconsFactory>
    </div>
  )
}

export default PrimaryCard
