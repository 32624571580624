import React from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { NewsletterForm } from "../../molecules/Newsletter/NewsletterForm.molecule"
import BackgroundIcons from "../../molecules/BackgroundIcons/BackgroundIcons.molecule"
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import { BreakpointTypes } from "../../../interfaces/enums/BreakpointTypes.enum"

import "animate.css/animate.min.css"
import SocialLink from "../../atoms/SocialLink/SocialLink.atom"
import { isEditMode } from "../../../utils/isEditMode.util"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface NewsletterProps extends BlokWithBorderIcons {
  title: string
  sub_title: string
  form_url: string
  background: StoryblokAsset
  cta_label: string
  social_icons: string[]
  social_links: any[]
}

const Newsletter: React.FC<NewsletterProps> = ({
  title,
  sub_title,
  form_url,
  background,
  cta_label,
  social_links,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  return (
    <section className="bg-SP6">
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto py-16 xl:py-20">
          <BackgroundIcons
            type={BackgroundPositionTypes.BottomLeftToTopRight_Inside}
            visibleOn={BreakpointTypes.LG}
            icon={background}
            className="w-[134px]"
            mirrorXRight
          >
            <div className="text-center pb-0">
              {title && (
                <h2 className="text-White text-xl md:text-2xl xl:text-3xl 3xl:text-4xl font-extrabold">
                  {title}
                </h2>
              )}
              {sub_title && (
                <p className="hidden md:block text-lg text-White pt-2 max-w-[53rem] m-auto">
                  {sub_title}
                </p>
              )}
              {form_url && (
                <div className="flex justify-center">
                  <MailchimpSubscribe
                    url={form_url}
                    render={({ subscribe, status, message }) => (
                      <NewsletterForm
                        status={status}
                        message={message}
                        onValidated={(formData: any) => subscribe(formData)}
                        cta_label={cta_label}
                      />
                    )}
                  />
                </div>
              )}

              {!form_url && isEditMode() && (
                <div className="flex justify-center">
                  <p>No form url provided</p>
                </div>
              )}
            </div>
          </BackgroundIcons>
          <div className="flex justify-center pt-6 md:pt-10">
            {social_links && !!social_links.length && (
              <div className="flex justify-center items-center flex-wrap w-80 gap-6 md:w-full">
                {social_links.map((social_link, i) => (
                  <SocialLink
                    key={social_link.content._uid}
                    type={social_link.content.type}
                    url={social_link.content.url}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default Newsletter
