/*-- npm packages --*/
import React from "react"
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"

/*-- component atoms --*/
import CustomLink from "../../atoms/CustomLink/CustomLink.atom"

/*-- interfaces --*/
import { NavItem } from "../../../interfaces/Navigation.interface"
import Blok from "../../../interfaces/Storyblok/Blok.interface"

/*-- assets --*/
import ChevronDown from "../../../assets/svgs/ChevronDown.svg"

interface AccordionStateProps {
  expanded?: boolean
  disabled?: boolean
}
interface AccorionItemLinkProps extends Blok {
  link: NavItem
  state: AccordionStateProps
}

interface AccorionItemProps {
  link: NavItem
}

const Link: React.FC<AccorionItemLinkProps> = ({ link, state }) => {
  return (
    <div
      className={`w-full flex items-center justify-between text-Primary-900 text-sm font-bold p-4 ${
        state.expanded ? "bg-SP7" : "bg-White"
      }`}
    >
      {link.display_name && (
        <span className="pr-2 text-Primary-700 group-hover:text-Primary-900">
          {link.display_name}
        </span>
      )}
      {!!link.dropdown_list?.length && (
        <ChevronDown className="text-Secondary-300" rotate={state.expanded} />
      )}
    </div>
  )
}
const AccorionItem: React.FC<AccorionItemProps> = ({ link }) => {
  return (
    <AccordionItem key={link._uid} uuid={link._uid} className="group">
      <AccordionItemHeading>
        <AccordionItemButton className="focus:ring-0 focus:outline-none">
          <AccordionItemState>
            {state => state && <Link state={state} link={link} />}
          </AccordionItemState>
        </AccordionItemButton>
      </AccordionItemHeading>

      <AccordionItemPanel className="px-6 bg-SP7">
        <CustomLink
          url={link.page_link.cached_url}
          className={`block py-3 text-Body font-semibold text-sm`}
          key={link._uid}
        >
          <span>{link.display_name}</span>
        </CustomLink>

        {link.dropdown_list?.length &&
          link.dropdown_list.map(link => (
            <CustomLink
              url={link.page_link.cached_url}
              className={`block py-3 text-Body font-semibold text-sm`}
              key={link._uid}
            >
              <span>{link.display_name}</span>
            </CustomLink>
          ))}
      </AccordionItemPanel>
    </AccordionItem>
  )
}

export default AccorionItem
