import React from "react"
import classNames from "classnames"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"
import CustomLink from "../../atoms/CustomLink/CustomLink.atom"
interface IconButtonProps {
  type: ButtonTypes
  icon?: React.ReactNode
  storyblokIcon?: StoryblokAsset
  active?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  styles?: string
  ariaLabel?: string
  background?: string
  size?: string
  href?: string
}

const IconButton: React.FC<IconButtonProps> = ({
  type,
  active,
  icon,
  storyblokIcon,
  onClick,
  styles,
  ariaLabel,
  size = "min-w-[40px] min-h-[40px] xl:min-w-[3rem] xl:min-h-[3rem]",
  href,
}) => {
  const IconButtonClasses = classNames(
    `${styles} ${size} rounded-full flex items-center justify-center`,
    {
      "bg-White": type === ButtonTypes.PRIMARY,
      "bg-Secondary-100": type === ButtonTypes.SECONDARY,
      "bg-White md:bg-Primary-900": type === ButtonTypes.TERTIARY,
    }
  )

  return (
    <>
      {href ? (
        <CustomLink className={IconButtonClasses} url={href}>
          {icon && icon}
          {storyblokIcon && <Image image={storyblokIcon} />}
        </CustomLink>
      ) : (
        <button
          onClick={onClick}
          className={IconButtonClasses}
          aria-label={ariaLabel}
        >
          {icon && icon}
          {storyblokIcon && <Image image={storyblokIcon} />}
        </button>
      )}
    </>
  )
}

export default IconButton
