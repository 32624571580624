import classNames from "classnames"
import React, { useState } from "react"
import { PreviewCardContent } from "../../../interfaces/Storyblok/PreviewCardContent.interface"
import CustomLink from "../../atoms/CustomLink/CustomLink.atom"
import Image from "../../atoms/Image/Image.atom"
import getFormattedDate from "../../../utils/getFormattedDate.util"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"
import { BorderIconsProps } from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface RelatedArticleCardProps extends BorderIconsProps {
  articlePreviewContent: PreviewCardContent
  articleCardDate: string | undefined
  articleCardFullSlug: string
  index: number
  numberOfArticles: number
}

const RelatedArticleCard: React.FC<RelatedArticleCardProps> = ({
  articlePreviewContent,
  articleCardDate,
  articleCardFullSlug,
  index,
  numberOfArticles,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false)

  const isFeaturedArticle = index === 0

  const getRelatedArticleCardClassNames = classNames(
    "col-span-2 md:col-span-12 2xl:flex 2xl:max-w-[32rem]",
    {
      "2xl:col-span-6": numberOfArticles === 2,
      "2xl:col-span-4": numberOfArticles === 3,
      "transition-all duration-500 ease-in-out": !isFeaturedArticle,
      "opacity-0 translate-y-20 2xl:translate-y-0 2xl:translate-x-20":
        !didBecomeVisible && !isFeaturedArticle,
      "lg:delay-[200ms]": index === 1,
      "lg:delay-[400ms]": index === 2,
      "opacity-100 translate-y-0 2xl:translate-x-0":
        didBecomeVisible || isFeaturedArticle,
    }
  )

  const getRelatedArticleCardInsertClassNames = classNames(
    "flex flex-col items-start justify-start font-monserrat rounded-6xl lg:flex-row 2xl:flex-col",
    {
      "bg-Primary-900 mt-8 md:mt-10 lg:mt-0": isFeaturedArticle,
      "bg-White": !isFeaturedArticle,
    }
  )

  const getRelatedArticleImageContainerClassNames = classNames(
    "w-full aspect-video rounded-t-6xl lg:h-full lg:w-fit lg:aspect-square lg:rounded-l-6xl lg:rounded-r-none xl:aspect-video xl:w-full xl:max-w-[340px] 2xl:max-w-none 2xl:rounded-t-6xl",
    {
      "px-4 lg:px-0 2xl:px-4": isFeaturedArticle,
      "2xl:max-h-[206px]": !isFeaturedArticle,
    }
  )

  const getRelatedArticleImageClassNames = classNames(
    "w-full h-full rounded-t-6xl overflow-hidden lg:h-auto lg:rounded-l-6xl lg:rounded-r-none 2xl:rounded-t-6xl 2xl:h-full",
    {
      "rounded-b-6xl relative -top-8 md:-top-10 lg:top-0 2xl:rounded-b-6xl 2xl:-top-8":
        isFeaturedArticle,
      "2xl:rounded-b-none": !isFeaturedArticle,
    }
  )

  const getRelatedArticlesContentClassNames = classNames(
    "px-10 py-8 space-y-8 lg:py-11 lg:space-y-6 xl:py-6",
    {
      "pt-0 2xl:pt-0": isFeaturedArticle,
      "": !isFeaturedArticle,
    }
  )

  const getRelatedArticleCardTitleClassNames = classNames(
    "font-semibold leading-none",
    {
      "text-White text-2xl": isFeaturedArticle,
      "text-Primary-900 text-xl": !isFeaturedArticle,
    }
  )

  const getDateAndAuthorRelatedClassNames = classNames(
    "flex items-center justify-start text-sm space-x-1",
    {
      "text-SP1": isFeaturedArticle,
      "text-Primary-900": !isFeaturedArticle,
    }
  )

  const getRelatedArticleDescriptionClassNames = classNames("", {
    "text-White": isFeaturedArticle,
    "text-Body": !isFeaturedArticle,
  })

  const getFormattedPreviewContentTag = (tag: string): string => {
    return tag
      .replaceAll("_", " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  }

  return (
    <CustomLink
      url={articleCardFullSlug}
      className={getRelatedArticleCardClassNames}
    >
      <AnimateInComponent
        wrapperComponent={"div"}
        didBecomeVisible={() => setDidBecomeVisible(true)}
      >
        <BorderIconsFactory
          type={BorderIconType.Card}
          border_icon_top_left={border_icon_top_left}
          border_icon_top_right={border_icon_top_right}
          border_icon_bottom_left={border_icon_bottom_left}
          border_icon_bottom_right={border_icon_bottom_right}
        >
          <div className={getRelatedArticleCardInsertClassNames}>
            <div className={getRelatedArticleImageContainerClassNames}>
              <div className={getRelatedArticleImageClassNames}>
                <Image image={articlePreviewContent.image} objectFit="cover" />
              </div>
            </div>
            <div className={getRelatedArticlesContentClassNames}>
              <div className="space-y-4">
                <div className="space-y-2">
                  <h4 className={getRelatedArticleCardTitleClassNames}>
                    {articlePreviewContent.title}
                  </h4>
                  <div className={getDateAndAuthorRelatedClassNames}>
                    {articleCardDate && (
                      <>
                        <p>{getFormattedDate(articleCardDate)}</p>
                        <span>-</span>
                      </>
                    )}
                    <p className="font-semibold">
                      {articlePreviewContent.author}
                    </p>
                  </div>
                </div>
                <p className={getRelatedArticleDescriptionClassNames}>
                  {articlePreviewContent.description}
                </p>
              </div>
              {articlePreviewContent.tag && (
                <p className="block bg-Secondary-100 text-Primary-900 text-xs font-semibold rounded-2xl px-2 py-1 w-fit">
                  {getFormattedPreviewContentTag(articlePreviewContent.tag)}
                </p>
              )}
            </div>
          </div>
        </BorderIconsFactory>
      </AnimateInComponent>
    </CustomLink>
  )
}

export default RelatedArticleCard
