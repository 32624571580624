import React from 'react';

interface BuildingType {
  name: string;
  description: string;
  icon: string;
  cardIcon: string;
  websiteFilterItem: string;
}

interface Facility {
  name: string;
  icon: string;
  cardIcon: string;
  websiteFilterItem: string;
  location: string;
}

export interface Club {
  name: string;
  description: string;
  icon: string;
  clubType: string;
  cardIcon: string;
  websiteFilterItem: string;
}

interface InspectionStandard {
  referenceNumber: string;
  type: string;
  rating: string;
  applicationDate: string;
  registrationDate: string;
  resignedDate: string;
  reportLink: string;
}

export interface Event {
  activity: string;
  description: string;
  date: string;
  image: string;
  additionalCost: string;
  type: string;
  discountedCost: string;
  eventName: string;
  group: string;
  startTime: string;
  endTime: string;
}

export interface Service {
  reference: string;
  name: string;
  daysRunning: number;
  term: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  currentPrice: number;
  tieredPricing: boolean;
  prices: any[];
  discountedPrice: number;
  bookingStartDate: string;
  bookingEndDate: string;
  earlyFinish: string;
  lateFee: number;
  cancellationPeriod: string;
  registrationFee: string;
  events: Event[];
  clubType: string;
  bookingCutOff: string;
}

export interface VenueWithinRange extends Venue {
  distanceBetween: number;
}
export interface Venue {
  id: number;
  name: string;
  status: string;
  buildingType: BuildingType;
  age: {
    min: number;
    max: number;
  };
  nearbyVenues: number[];
  contactNumber: string;
  contactEmail: string;
  address: {
    line1: string;
    line2: string;
    townCity: string;
    county: string;
    postcode: string;
    localAuthority: string;
    region: string;
  };
  coords: {
    lat: number;
    lng: number;
  };
  facilities: Facility[];
  links: {
    website_url: string;
    venueWebsite: string;
    bookingLink: string;
    availabiltyLink: string;
    registrationLink: string;
  };
  permanentBookingCancellation: string;
  pickupLateFee: number;
  pickupAndDropoffInstructions: string;
  noShowFee: number;
  dailyDiscount: string;
  weeklyDiscount: string;
  siblingDiscount: string;
  serviceListInfoTitle: string;
  serviceListInfoCopy: string;
  inspectionStandards: InspectionStandard | undefined;
  clubs: Club[];
  services: Service[];
  termsLink: string;
}

const VenuesContext = React.createContext<Venue[]>([]);

export default VenuesContext;
