import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import CustomLink from "../../atoms/CustomLink/CustomLink.atom"
import IconButton from "../../atoms/IconButton/IconButton.component"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import Phone from "../../../assets/svgs/Phone.svg"
import Email from "../../../assets/svgs/Email.svg"
import Location from "../../../assets/svgs/Location.svg"

interface ListItem extends Blok {
  text: string
}

interface FooterContactDetailsProps {
  section_header: string
  descriptive_text: string
  list_items: ListItem[]
  page_link: StoryblokLink
  phone: string
  email: string
  address: string
}

const FooterContactDetails: React.FC<FooterContactDetailsProps> = ({
  section_header,
  descriptive_text,
  list_items,
  page_link,
  email,
  phone,
  address,
}) => {
  const handleContactUsLinkClickDataLayerEvent = (
    contactType: "phone" | "email",
    contactValue: string
  ): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "contactUs",
      contactType,
      contactValue,
      locationUrl: window.location,
      _clear: true,
    })
  }

  return (
    <div className="flex justify-center items-center xl:block">
      <div className="text-center xl:text-left">
        {page_link?.cached_url !== "" ? (
          <CustomLink
            url={page_link?.cached_url}
            className={`md:pb-3 text-lg sm:text-xl font-bold`}
          >
            <h3>{section_header}</h3>
          </CustomLink>
        ) : (
          <h3 className="md:pb-3 text-lg sm:text-xl font-bold">
            {section_header}
          </h3>
        )}
        <p className="pb-8 md:pb-2">{descriptive_text}</p>

        <div className="flex items-center justify-center flex-wrap gap-x-6 xl:block">
          {phone && (
            <CustomLink
              className="flex items-center group"
              url={`tel:${phone}`}
              onClick={() =>
                handleContactUsLinkClickDataLayerEvent("phone", phone)
              }
            >
              <IconButton
                type={ButtonTypes.TERTIARY}
                icon={
                  <Phone className="text-Primary-900 md:text-Secondary-200" />
                }
                size="min-w-[48px] min-h-[48px] xl:min-w-[3rem] xl:min-h-[3rem]"
                styles={`flex md:-translate-x-2`}
                ariaLabel="search"
              />
              <span className="hidden md:block font-bold text-White group-hover:text-SP1 transition-all">
                {phone}
              </span>
            </CustomLink>
          )}
          {email && (
            <CustomLink
              className="flex items-center group xl:-mt-2"
              url={`mailto:${email}`}
              onClick={() =>
                handleContactUsLinkClickDataLayerEvent("email", email)
              }
            >
              <IconButton
                type={ButtonTypes.TERTIARY}
                icon={
                  <Email className="text-Primary-900 md:text-Secondary-200" />
                }
                size="min-w-[48px] min-h-[48px] xl:min-w-[3rem] xl:min-h-[3rem]"
                styles={`flex md:-translate-x-2`}
                ariaLabel="search"
              />

              <span className="hidden md:block font-bold text-White group-hover:text-SP1 transition-all underline break-all">
                {email}
              </span>
            </CustomLink>
          )}
          {address && (
            <CustomLink
              className="flex items-center xl:pb-2 group xl:-mt-2"
              url={`https://www.google.com/maps?q=${encodeURI(address)}`}
            >
              <IconButton
                type={ButtonTypes.TERTIARY}
                icon={
                  <Location className="text-Primary-900 md:text-Secondary-200" />
                }
                size="min-w-[48px] min-h-[48px] xl:min-w-[3rem] xl:min-h-[3rem]"
                styles={`flex md:-translate-x-2`}
                ariaLabel="search"
              />
              <span className="hidden md:block font-bold text-White group-hover:text-SP1 transition-all underline">
                {address}
              </span>
            </CustomLink>
          )}
        </div>

        <ul className="xl:pb-4 flex justify-center space-x-6 xl:space-x-0 xl:block">
          {list_items &&
            !!list_items.length &&
            list_items.map(
              link =>
                link.text && (
                  <li
                    key={link._uid}
                    className="xl:pb-4 hidden md:block w-full"
                  >
                    {link.text}
                  </li>
                )
            )}
        </ul>
      </div>
    </div>
  )
}

export default FooterContactDetails
