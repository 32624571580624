import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api"
import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { Venue } from "../../../contexts/VenuesContext"
import {
  VenueIndexSearchBarProps,
  VenueIndexMapConfigProps,
  VenueIndexCardConfigProps,
} from "../../../interfaces/VenueIndex/VenueIndex.interface"
import VenueIndexSearchBar from "../../molecules/VenueIndexSearchBar/VenueIndexSearchBar.molecule"
import VenueIndexResultCard from "../../molecules/VenueIndexResultCard/VenueIndexResultCard.component"
import { FindUsResultsCardType } from "../FindUsFilterAndResults/FindUsResults/FindUsResultsListCard.component"
import { mapStyles } from "./MapStyles"

interface VenueIndexMapProps {
  map_config: VenueIndexMapConfigProps[]
  search_bar_config: VenueIndexSearchBarProps[]
  card_config: VenueIndexCardConfigProps[]
  venues: Venue[]
}

const VenueIndexMap: React.FC<VenueIndexMapProps> = ({
  map_config,
  search_bar_config,
  card_config,
  venues,
}) => {
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null)

  const CENTRE_OF_UK_LAT_LNG = { lat: 52.3342942, lng: -1.2753474 }

  let mapRef: GoogleMap
  const [isMapRefInitialized, setIsMapRefInitialized] = useState<boolean>(false)

  const getMapConfig = (): VenueIndexMapConfigProps | undefined => {
    return map_config?.length > 0 ? map_config[0] : undefined
  }

  const getMarkerIcon = (venue: Venue): string | undefined => {
    return getMapConfig()
      ? venue === selectedVenue
        ? getMapConfig()?.selected_icon.filename
        : getMapConfig()?.default_icon.filename
      : undefined
  }

  const sm = useMediaQuery({
    query: "(max-width: 799px)",
  })
  const md = useMediaQuery({
    query: "(max-width: 1023px)",
  })
  const lg = useMediaQuery({
    query: "(max-width: 1279px)",
  })
  const xl = useMediaQuery({
    query: "(max-width: 1439px)",
  })

  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -height - 60,
  })

  useEffect(() => {
    if (selectedVenue) {
      panToselectedVenue()
    }
  }, [selectedVenue])

  const panToselectedVenue = (): void => {
    if (selectedVenue) {
      const coordinatesToPanTo = {
        lat: Number(selectedVenue.coords.lat),
        lng: Number(selectedVenue.coords.lng),
      }

      mapRef.state.map?.panTo(coordinatesToPanTo)
      mapRef.state.map?.panBy(0, -180)
    }
  }

  useEffect(() => {
    updateMapBounds()
  }, [isMapRefInitialized])

  useEffect(() => {
    updateMapBounds()
  }, [venues])

  const updateMapBounds = (): void => {
    if (mapRef) {
      const bounds = new window.google.maps.LatLngBounds()

      if (venues.length > 0) {
        venues.map((venue: Venue) => {
          if (venue.coords.lat && venue.coords.lng) {
            const coordinatesToAddToBounds = {
              lat: Number(venue.coords.lat),
              lng: Number(venue.coords.lng),
            }
            bounds.extend(
              new window.google.maps.LatLng(coordinatesToAddToBounds)
            )
          }
        })
        mapRef?.state.map?.fitBounds(bounds)
      }
    }
  }

  return (
    <section className="relative w-screen h-[336px] lg:h-[400px] xl:h-[500px] 2xl:h-[600px] 3xl:h-[740px]">
      {/*-- google maps --*/}
      <LoadScript
        googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY || ""}
      >
        <GoogleMap
          mapContainerClassName="w-full h-full"
          ref={(ref: GoogleMap) => {
            if (!mapRef) {
              mapRef = ref
              setIsMapRefInitialized(true)
            }
          }}
          options={{
            styles: mapStyles,
            zoomControl: true,
            maxZoom: 14,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
        >
          <div className="hidden md:block">
            {venues.length &&
              !sm &&
              venues.map(
                (venue: Venue, idx: number) =>
                  venue.coords.lat &&
                  venue.coords.lng && (
                    <Marker
                      key={`map-marker-${idx}`}
                      position={{
                        lat: venue.coords.lat,
                        lng: venue.coords.lng,
                      }}
                      icon={getMarkerIcon(venue)}
                      onClick={() => {
                        selectedVenue?.id === venue.id
                          ? setSelectedVenue(null)
                          : setSelectedVenue(venue)
                      }}
                    />
                  )
              )}

            {selectedVenue && (
              <OverlayView
                position={{
                  lat: selectedVenue.coords.lat,
                  lng: selectedVenue.coords.lng,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getPixelPositionOffset}
              >
                <VenueIndexResultCard
                  venue={selectedVenue}
                  card_config={card_config}
                  type={FindUsResultsCardType.MAP}
                />
              </OverlayView>
            )}
          </div>
        </GoogleMap>
      </LoadScript>

      {/*-- search bar --*/}
      <div className="absolute top-0 right-0 bottom-0 left-0 pointer-events-none">
        <div className="h-full container mx-auto grid grid-cols-2 grid-rows-2 gap-x-6 justify-center md:grid-cols-12 md:grid-rows-1">
          <div className="col-span-2 flex items-center justify-center md:col-span-12 md:items-start md:justify-start md:pt-14 2xl:pt-20">
            <div className="rounded-6xl bg-Primary-900 p-4 w-fit font-montserrat font-extrabold text-White text-2xl md:px-9 md:py-6 lg:text-3xl xl:text-4xl 2xl:text-5xl">
              <h1>{getMapConfig()?.title_label || "Find a club"}</h1>
            </div>
          </div>
          {search_bar_config?.map(
            (search_bar_config_item: VenueIndexSearchBarProps) => (
              <div
                key="search-bar-config"
                className="col-span-2 pointer-events-auto md:col-span-8 md:col-start-3 md:relative md:top-7 lg:top-8"
              >
                <VenueIndexSearchBar {...search_bar_config_item} />
              </div>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default VenueIndexMap
