import classNames from "classnames"
import React from "react"
import StoryblokFAQ from "../../../interfaces/Storyblok/StoryblokFAQ.interface"
import { Collapse } from "react-collapse"
import RichText from "../../atoms/RichText/RichText.atom"

interface FAQProps {
  faq: StoryblokFAQ
  index: number
  selectedFaqIndex: number | undefined
  faqWasClicked: () => void
}

const FAQ: React.FC<FAQProps> = ({
  faq,
  index,
  selectedFaqIndex,
  faqWasClicked,
}) => {
  const isFAQSelected = (): boolean => {
    return selectedFaqIndex === index
  }

  const faqClassNames = classNames(
    "w-full h-fit rounded-7xl font-monserrat transition-all duration-1000 delay-0 space-y-4",
    {
      "bg-SP7": isFAQSelected(),
      "bg-none": !isFAQSelected(),
    }
  )

  const faqQuestionClassNames = classNames(
    "cursor-pointer flex items-center justify-between border-b transition-all text-lg font-semibold w-full px-6 space-x-4 md:text-xl",
    {
      "border-b-Transparent text-Primary-900 pt-8": isFAQSelected(),
      "border-b-Body-Disabled text-Body py-4": !isFAQSelected(),
    }
  )

  const faqAnswerClassNames = classNames("block px-6 pb-6", {
    "duration-1000 opacity-100": isFAQSelected(),
    "duration-1000 opacity-0": !isFAQSelected(),
  })

  return (
    <li className={faqClassNames}>
      <button onClick={() => faqWasClicked()} className={faqQuestionClassNames}>
        <p className="flex-1 text-left">{faq.content.question}</p>
        <OpenCloseIcon isFAQSelected={isFAQSelected()} />
      </button>
      <Collapse isOpened={isFAQSelected()}>
        <div className={faqAnswerClassNames}>
          <RichText content={faq.content.answer} />
        </div>
        {/* <p className={faqAnswerClassNames}>{faq.content.answer}</p> */}
      </Collapse>
    </li>
  )
}

interface OpenCloseIconProps {
  isFAQSelected: boolean
}

const OpenCloseIcon: React.FC<OpenCloseIconProps> = ({ isFAQSelected }) => {
  const openCloseIconClassNames: string = classNames(
    "w-[26px] h-[26px] border-2 rounded-full flex flex-col items-center justify-center",
    {
      "border-Body": !isFAQSelected,
      "border-Primary-900": isFAQSelected,
    }
  )

  const getLineClassNames = (isRotatableLine: boolean = false): string => {
    return classNames(
      "w-3 h-[2px] relative rounded-full transition-all duration-600",
      {
        "bg-Body": !isFAQSelected,
        "bg-Primary-900": isFAQSelected,
        "rotate-90": isRotatableLine && !isFAQSelected,
        "top-[1.5px]": !isRotatableLine,
        "-top-[0.5px]": isRotatableLine,
      }
    )
  }

  return (
    <div className={openCloseIconClassNames}>
      <div className={getLineClassNames()}></div>
      <div className={getLineClassNames(true)}></div>
    </div>
  )
}

export default FAQ
