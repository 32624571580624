import React, { useState, useEffect } from "react"
import { NewsletterBtn } from "./NewsletterBtn.molecule"
import classNames from "classnames"

interface NewsletterFormProps {
  status: string | null
  message: string | Error | null
  onValidated: any
  cta_label: string
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
  status,
  message,
  onValidated,
  cta_label,
}) => {
  const [emailField, setEmailField] = useState("")
  const [fnameField, setFnameField] = useState("")
  const [lnameField, setLnameField] = useState("")
  const [formError, setFormError] = useState("")

  useEffect(() => {
    if (status === "success") handleNewsletterSubmissionDataLayerEvent()
  }, [status])

  const handleNewsletterSubmissionDataLayerEvent = (): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "newsletterSignup",
      locationURL: window.location,
      _clear: true,
    })
  }

  const fieldClasses = classNames(
    `w-full h-[52px] rounded-full px-4 focus:ring-0 focus:outline-none text-sm text-Primary-900`
  )
  const labelClasses = classNames(`text-sm font-bold text-White pb-[1px] block`)
  const formErrorClasses = classNames(
    `text-Primary-900 text-sm xl:text-base font-extrabold text-center uppercase no-underline leading-[1.5rem]`
  )

  let email: HTMLInputElement, fname: HTMLInputElement, lname: HTMLInputElement
  const submit = () => {
    const validateFields = (): boolean => {
      if (
        !Boolean(email.value) ||
        !Boolean(fname.value) ||
        !Boolean(lname.value)
      ) {
        setFormError("Please populate the required fields")
        return false
      } else if (!email.value.includes("@")) {
        setFormError("Please enter a valid email address")
        return false
      } else {
        setFormError("")
        return true
      }
    }

    validateFields() &&
      onValidated({
        MERGE0: email.value,
        MERGE1: fname.value,
        MERGE2: lname.value,
      })
  }

  const validateFields = () => {
    !emailField &&
      !fnameField &&
      !lnameField &&
      setFormError("Please populate the required fields")
  }

  return (
    <div className="w-[495px]">
      <div className="py-8 space-y-6">
        <div className="text-left">
          <label htmlFor="email" className={labelClasses}>
            Email
          </label>
          <input
            ref={node => (email = node)}
            type="text"
            name="email"
            id="email"
            className={fieldClasses}
            onChange={e => setEmailField(e.target.value)}
          />
        </div>
        <div className="text-left">
          <label htmlFor="fname" className={labelClasses}>
            First Name
          </label>
          <input
            ref={node => (fname = node)}
            type="text"
            name="fname"
            id="fname"
            className={fieldClasses}
            onChange={e => setFnameField(e.target.value)}
          />
        </div>
        <div className="text-left">
          <label htmlFor="lname" className={labelClasses}>
            Last Name
          </label>
          <input
            ref={node => (lname = node)}
            type="text"
            name="lname"
            id="lname"
            className={fieldClasses}
            onChange={e => setLnameField(e.target.value)}
          />
        </div>
      </div>

      <NewsletterBtn
        status={status}
        message={message}
        cta_label={cta_label}
        submit={() => {
          validateFields()
          submit()
        }}
        formError={!emailField && !fnameField && !lnameField ? formError : ""}
      ></NewsletterBtn>

      {formError ? (
        <div className={`${formErrorClasses} pt-6 pb-4`}>{formError}</div>
      ) : status === "error" ? (
        <div
          className={`${formErrorClasses} pt-4`}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : status === "success" ? (
        <div className={`${formErrorClasses} pt-6 pb-4`}>
          Thank you for subscribing!
        </div>
      ) : (
        <div className="py-8" />
      )}
    </div>
  )
}
