export const mapStyles = [
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      {
        hue: "#7B00FF",
      },
      {
        saturation: "28",
      },
      {
        lightness: "17",
      },
      {
        gamma: "0.87",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -11,
      },
      {
        color: "#D4D7F1",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -78,
      },
      {
        hue: "#6600FF",
      },
      {
        lightness: -47,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -79,
      },
      {
        color: "#866CD1",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        lightness: 30,
      },
      {
        weight: 1.3,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        hue: "#5E00FF",
      },
      {
        saturation: -16,
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "all",
    stylers: [
      {
        saturation: -72,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        saturation: "1",
      },
      {
        lightness: "5",
      },
      {
        gamma: "10.00",
      },
      {
        color: "#9986D8",
      },
    ],
  },
]
