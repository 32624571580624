import React from "react"

interface BackgroundSquiggleProps {
  className?: string
}

const BackgroundSquiggle: React.FC<BackgroundSquiggleProps> = ({
  className,
}) => {
  return (
    <svg
      width="169"
      height="261"
      viewBox="0 0 169 261"
      className={`transfrom fill-current ${className}`}
    >
      <path
        d="M159.487 154.007C158.045 151.908 156.358 149.936 154.514 148.092C155.887 145.316 157.035 142.54 157.967 139.901C168.098 110.935 158.281 76.1529 136.39 55.142C111.782 31.5219 75.3656 24.3908 42.5292 30.3743C38.4884 31.11 34.5068 32.0712 30.564 33.2188C24.1988 35.0629 26.6704 45.0681 33.0552 43.2241C59.8598 35.4553 90.2537 37.0934 114.597 51.5028C136.978 64.7547 151.336 88.8947 151.582 115.045C151.67 124.217 149.914 133.29 146.178 141.569C136.743 135.899 125.228 133.938 114.911 138.95C107.447 142.569 102.435 150.819 105.329 159.078C108.006 166.719 115.901 172.163 123.963 172.301C134.467 172.477 142.902 165.738 149.042 157.214C157.741 167.7 159.379 182.826 157.496 195.94C155.211 211.86 147.414 227.064 134.938 237.344C122.59 247.506 105.495 252.097 89.8224 248.605C83.3787 247.173 80.5538 257.09 87.0563 258.541C104.004 262.308 122.168 258.679 136.527 248.928C151.327 238.874 161.576 223.248 165.96 205.985C170.275 188.995 169.716 168.877 159.497 153.997L159.487 154.007ZM125.591 161.942C121.128 162.472 114.499 159.078 114.773 153.869C115.097 147.758 124.1 146.277 128.73 146.464C133.114 146.65 137.4 148.131 141.176 150.417C137.547 155.635 132.192 161.158 125.591 161.933V161.942Z"
        fill="#7E62BD"
      />
      <path
        d="M47.5684 63.6654C41.9191 57.8879 35.7502 52.6303 29.13 47.9809C25.9425 45.7444 22.6468 43.6551 19.2533 41.7325C17.5565 40.7713 15.8402 39.8492 14.1042 38.9762C13.3981 38.623 12.6917 38.2896 11.9856 37.9365C13.8883 36.2297 15.9677 34.6406 17.8214 33.0221C20.9108 30.3247 24.0005 27.637 27.0899 24.9395C33.2688 19.5544 39.4476 14.1693 45.6265 8.78416C50.6382 4.41916 43.508 -3.03566 38.5158 1.31953C31.1012 7.78366 23.6866 14.2477 16.2621 20.7118C12.7117 23.8115 9.15137 26.9014 5.60097 30.001C2.23691 32.9339 -1.53888 36.4847 0.648244 41.3598C2.35479 45.1657 6.26786 46.5095 9.72999 48.2947C13.506 50.2467 17.1841 52.3949 20.7345 54.7295C27.7765 59.3593 34.2005 64.7444 40.0852 70.777C44.7243 75.5245 52.1878 68.413 47.5487 63.6654H47.5684Z"
        fill="#7E62BD"
      />
    </svg>
  )
}

export default BackgroundSquiggle
