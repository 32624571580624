import React from 'react';
import CustomLink from '../../atoms/CustomLink/CustomLink.atom';
import { NavItem } from '../../../interfaces/Navigation.interface';
import DropdownMenu from '../DropdownMenu/DropdownMenu.molecule';
import ChevronDown from '../../../assets/svgs/ChevronDown.svg';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import Button from '../../atoms/Button/Button.component';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';

interface PreNavProps {
  links: NavItem[];
  login_link: StoryblokLink;
}

const PreNav: React.FC<PreNavProps> = ({ links, login_link }) => {
  const handleDataLayerEvent = (label: string, link: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click',
      type: 'Pre Nav Menu',
      button: {
        text: label,
        url: link,
      },
    });
  };

  console.log(login_link);

  return (
    <nav className="lg:flex items-center justify-end space-x-6">
      <ul className="flex text-White">
        {links &&
          !!links.length &&
          links.map(
            link =>
              link.display_name && (
                <li key={link._uid} className="relative flex items-center group mx-6 last:mr-0">
                  <CustomLink
                    url={link.page_link.cached_url}
                    className="h-[72px] flex justify-center items-center text-sm xl:text-base group"
                    onClick={() => handleDataLayerEvent(link.display_name, link.page_link.cached_url)}
                  >
                    <span className="pr-2 group-hover:text-SP1">{link.display_name}</span>
                    {!!link.dropdown_list?.length && (
                      <ChevronDown className="text-White group-hover:text-SP1" rotateOnHover={true} />
                    )}
                  </CustomLink>
                  {!!link.dropdown_list?.length && (
                    <DropdownMenu
                      links={link.dropdown_list}
                      position="rounded-b-2xl drop-shadow-2xl lg:-left-14 xl:left-0 top-[99%]"
                      dataLayerEventType="Pre Nav Item Menu Dropdown"
                      parentLinkLabel={link.display_name}
                    />
                  )}
                </li>
              ),
          )}
      </ul>
      {login_link?.cached_url && (
        <Button
          link={login_link.cached_url}
          label={'LOGIN'}
          type={ButtonTypes.PRIMARY}
          size={ButtonSizeTypes.DEFAULT}
          className="bg-Secondary-100 hover:bg-Secondary-200"
        />
      )}
    </nav>
  );
};

export default PreNav;
