import React from "react"
import { StoryblokRichtext } from "storyblok-rich-text-react-renderer"
import classNames from "classnames"

// components
import Button from "../../atoms/Button/Button.component"

// atoms
import Image from "../../atoms/Image/Image.atom"
import RichText from "../../atoms/RichText/RichText.atom"

// types
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import { StoryblokIconListItem } from "../../../interfaces/Storyblok/StoryblokIconList.interface"
import { ButtonProps } from "../../../interfaces/Button.interface"

// enums
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import BlokWithBorderIcons from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface FullWidthProps extends BlokWithBorderIcons {
  icon: StoryblokAsset
  heading: string
  body: StoryblokRichtext
  list_heading: string
  list: StoryblokIconListItem[]
  cta: ButtonProps[]
  image: StoryblokAsset
  reversed: boolean
  theme: ThemeActivityCategory
}

const FullWidth: React.FC<FullWidthProps> = ({
  icon,
  heading,
  body,
  list_heading,
  list,
  cta,
  image,
  reversed,
  theme,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const THEME_ACTIVITY_CATEGORY_BACKGROUNDS: Record<
    ThemeActivityCategory,
    string
  > = {
    "Brain-Boosters": "bg-Brain-Boosters",
    "Zen-Masters": "bg-Zen-Masters",
    "Creative-Inventors": "bg-Creative-Inventors",
    "Globe-Trotters": "bg-Globe-Trotters",
    "Power-Teams": "bg-Power-Teams",
    "Splash-Zone": "bg-Splash-Zone",
    "Super-Sports": "bg-Super-Sports",
  }
  const THEME_ACTIVITY_CATEGORY_BORDERS: Record<ThemeActivityCategory, string> =
    {
      "Brain-Boosters": "border-Brain-Boosters",
      "Zen-Masters": "border-Zen-Masters",
      "Creative-Inventors": "border-Creative-Inventors",
      "Globe-Trotters": "border-Globe-Trotters",
      "Power-Teams": "border-Power-Teams",
      "Splash-Zone": "border-Splash-Zone",
      "Super-Sports": "border-Super-Sports",
    }

  const sectionClassNames = classNames("relative md:flex", {
    "flex-row-reverse": reversed,
    "flex-row": !reversed,
  })

  const contentColumnClassNames = classNames("", {
    "md:col-start-1": reversed,
    "md:col-start-2": !reversed,
  })

  const contentWrapperClassNames = classNames(
    `my-6
     md:my-16 md:mx-6
     lg:my-24 lg:mx-12
     `,
    {
      [`xl:border-l-4 xl:pl-6 ${THEME_ACTIVITY_CATEGORY_BORDERS[theme]}`]:
        icon?.filename,
    }
  )

  return (
    <section className={sectionClassNames}>
      <div className="aspect-video overflow-hidden sm:aspect-w-5 md:aspect-none md:absolute md:h-full md:w-1/2">
        <Image image={image} objectFit="cover" />
      </div>

      <div className="container mx-auto md:grid md:grid-cols-2">
        {/* This is the 50% area, maximised to the container width */}
        <div className={contentColumnClassNames}>
          {/* This is used to limit / position the content within the panel */}
          <BorderIconsFactory
            type={BorderIconType.Card}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <div className={contentWrapperClassNames}>
              <div className="flex items-center gap-4 mb-3">
                {icon?.filename && (
                  <Image image={icon} maxWidth={56} maxHeight={56} />
                )}
                <h2 className="text-2xl font-extrabold text-Primary-900">
                  {heading}
                </h2>
              </div>

              {body && (
                <div className="mb-4">
                  <RichText content={body} />
                </div>
              )}

              {list_heading && (
                <p className="text-lg font-semibold text-Primary-900 mb-4">
                  {list_heading}
                </p>
              )}

              <dl className="grid grid-cols-1 lg:grid-cols-2 gap-1 mb-6">
                {list.map(item => (
                  <div key={item._uid} className="flex gap-2 items-center">
                    <div
                      className={classNames(
                        "w-4 h-4 rounded-full",
                        THEME_ACTIVITY_CATEGORY_BACKGROUNDS[theme]
                      )}
                    />
                    <dt className="flex-1 font-semibold">{item.label}</dt>
                  </div>
                ))}
              </dl>

              {cta && !!cta.length && cta[0].label && cta[0].link && (
                <Button
                  link={cta[0].link}
                  label={cta[0].label}
                  type={ButtonTypes.PRIMARY}
                  size={ButtonSizeTypes.LG}
                  className={`w-full md:w-auto`}
                />
              )}
            </div>
          </BorderIconsFactory>
        </div>
      </div>
    </section>
  )
}

export default FullWidth
