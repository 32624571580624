import classNames from 'classnames';
import React from 'react';
import { Collapse } from 'react-collapse';
import RichText from '../../atoms/RichText/RichText.atom';
import { AccordionItemProps } from './Accordion.blok';

interface AccordionItem {
  accordionItem: AccordionItemProps;
  index: number;
  selectedAccordionItemIndex: number | undefined;
  AccordionItemWasClicked: () => void;
}

const AccordionItem: React.FC<AccordionItem> = ({
  accordionItem,
  index,
  selectedAccordionItemIndex,
  AccordionItemWasClicked,
}) => {
  const isAccordionItemSelected = (): boolean => {
    return selectedAccordionItemIndex === index;
  };

  const AccordionItemClassNames = classNames(
    'w-full h-fit rounded-7xl font-monserrat transition-all duration-1000 delay-0 space-y-4',
    {
      'bg-SP7': isAccordionItemSelected(),
      'bg-none': !isAccordionItemSelected(),
    },
  );

  const AccordionItemQuestionClassNames = classNames(
    'cursor-pointer flex items-center justify-between border-b transition-all text-lg font-semibold w-full px-6 space-x-4 md:text-xl',
    {
      'border-b-Transparent text-Primary-900 pt-8': isAccordionItemSelected(),
      'border-b-Body-Disabled text-Body py-4': !isAccordionItemSelected(),
    },
  );

  const AccordionItemAnswerClassNames = classNames('block px-6 pb-6', {
    'duration-1000 opacity-100': isAccordionItemSelected(),
    'duration-1000 opacity-0': !isAccordionItemSelected(),
  });

  return (
    <li className={AccordionItemClassNames}>
      <button onClick={() => AccordionItemWasClicked()} className={AccordionItemQuestionClassNames}>
        <p className="flex-1 text-left">{accordionItem?.content?.question}</p>
        <OpenCloseIcon isAccordionItemSelected={isAccordionItemSelected()} />
      </button>
      <Collapse isOpened={isAccordionItemSelected()}>
        <div className={AccordionItemAnswerClassNames}>
          <RichText content={accordionItem?.content?.answer} />
        </div>
        {/* <p className={AccordionItemAnswerClassNames}>{AccordionItem.content.answer}</p> */}
      </Collapse>
    </li>
  );
};

interface OpenCloseIconProps {
  isAccordionItemSelected: boolean;
}

const OpenCloseIcon: React.FC<OpenCloseIconProps> = ({ isAccordionItemSelected }) => {
  const openCloseIconClassNames: string = classNames(
    'w-[26px] h-[26px] border-2 rounded-full flex flex-col items-center justify-center',
    {
      'border-Body': !isAccordionItemSelected,
      'border-Primary-900': isAccordionItemSelected,
    },
  );

  const getLineClassNames = (isRotatableLine: boolean = false): string => {
    return classNames('w-3 h-[2px] relative rounded-full transition-all duration-600', {
      'bg-Body': !isAccordionItemSelected,
      'bg-Primary-900': isAccordionItemSelected,
      'rotate-90': isRotatableLine && !isAccordionItemSelected,
      'top-[1.5px]': !isRotatableLine,
      '-top-[0.5px]': isRotatableLine,
    });
  };

  return (
    <div className={openCloseIconClassNames}>
      <div className={getLineClassNames()}></div>
      <div className={getLineClassNames(true)}></div>
    </div>
  );
};

export default AccordionItem;
