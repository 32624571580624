import React, {
  useState,
  useContext,
  useEffect,
  RefObject,
  createRef,
  useMemo,
} from "react"
import classNames from "classnames"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import { StoryblokLink } from "../../../interfaces/Storyblok/StoryblokLink.interface"
import Image from "../../atoms/Image/Image.atom"
import RichText from "../../atoms/RichText/RichText.atom"
import FindClubs from "../../molecules/FindClubs/FindClubs.molecule"
import BackgroundIcons from "../../molecules/BackgroundIcons/BackgroundIcons.molecule"
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import HeroBannerContext from "../../../contexts/HeroBanner.context"
import { YoutubeVideoBlok } from "../../../interfaces/MediaAsset/YoutubeVideoBlok.interface"
import isEmptyString from "../../../utils/isEmptyString/isEmptyString.util"
import YoutubeVideo from "../../atoms/YoutubeVideo/YoutubeVideo.atom"
import VenueIndexSearchBar from "../../molecules/VenueIndexSearchBar/VenueIndexSearchBar.molecule"

interface ImageAsset extends Blok {
  image: StoryblokAsset
}
interface HeroProps {
  page_heading: any
  find_us_placeholder_text: string
  button_label: string
  button_link: StoryblokLink
  pre_selected_filter_options: string[]
  background_video: YoutubeVideoBlok[]
  background_image: ImageAsset[]
  icon_left: StoryblokAsset
  icon_right: StoryblokAsset
  id: string
}

const Hero: React.FC<HeroProps> = ({
  page_heading,
  find_us_placeholder_text,
  button_label,
  button_link,
  pre_selected_filter_options,
  background_video,
  background_image,
  icon_left,
  icon_right,
  id,
}) => {
  const numberOfBackgroundImages = background_image.length
  const [counter, setCounter] = useState(numberOfBackgroundImages)
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)

  const { isExtendedHeightRequired } = useContext(HeroBannerContext)

  const imageRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => background_image.map(() => createRef()),
    [background_image]
  )

  const changeBackgroundImage = (): void =>
    activeImageIndex < numberOfBackgroundImages - 1
      ? setActiveImageIndex(activeImageIndex + 1)
      : setActiveImageIndex(0)

  useEffect(() => {
    const timer: void | NodeJS.Timer =
      counter > 0
        ? setInterval(() => setCounter(counter - 1), 1000)
        : (() => {
            setCounter(numberOfBackgroundImages)
            changeBackgroundImage()
          })()
    if (typeof timer === "number") {
      return () => clearInterval(timer)
    }
  }, [counter])

  const heroHeight = classNames("", {
    "h-[330px] md:h-[420px] lg:h-[490px] xl:h-[580px] 2xl:h-[620px] 3xl:h-[822px]":
      isExtendedHeightRequired,
    "h-[509px] md:h-[372px] lg:h-[374px] xl:h-[468px] 2xl:h-[511px]":
      !isExtendedHeightRequired,
  })

  const isVideoBackgroundRequired = (): boolean => {
    return (
      background_video?.filter(
        (youtube_video_blok: YoutubeVideoBlok) =>
          !isEmptyString(youtube_video_blok.video_id)
      ).length > 0
    )
  }

  const isImageCarouselBackgroundRequired = (): boolean => {
    const hasImages: boolean =
      background_image.filter(
        (background_image_blok: ImageAsset) =>
          !isEmptyString(background_image_blok.image.filename)
      ).length > 0
    return !isVideoBackgroundRequired() && hasImages
  }

  return (
    <section className={`relative overflow-hidden ${heroHeight}`} id={id}>
      {isVideoBackgroundRequired() && (
        <div className={`absolute top-0 left-0 bottom-0 right-0 -z-10`}>
          <YoutubeVideo
            video_id={background_video[0].video_id}
            autoplay
            loop
            isForcedFullScreenRequired={true}
          />
          <div
            className={`absolute top-0 left-0 bottom-0 right-0 -z-5 bg-gradient-to-b from-Homepage-Hero/Gradient-Start to-Homepage-Hero/Gradient-End`}
          ></div>
        </div>
      )}

      {isImageCarouselBackgroundRequired() && (
        <div className={`absolute top-0 left-0 bottom-0 right-0 -z-10`}>
          {background_image.map((image, index) => (
            <div
              className={`absolute top-0 left-0 bottom-0 right-0 -z-20 transition-all duration-500 bg-Primary-900 ${
                index === activeImageIndex ? "opacity-100" : "opacity-0"
              }`}
              ref={imageRefs[index]}
              key={image._uid}
            >
              <Image image={image.image} objectFit="cover"></Image>
            </div>
          ))}
          <div
            className={`absolute top-0 left-0 bottom-0 right-0 -z-10 bg-gradient-to-b from-Homepage-Hero/Gradient-Start to-Homepage-Hero/Gradient-End`}
          ></div>
        </div>
      )}

      <div className="container mx-auto h-full py-7 md:py-20 lg:py-28 xl:py-32 2xl:py-36">
        <BackgroundIcons
          type={BackgroundPositionTypes.BottomLeftToTopRight_Quarter}
          iconLeft={icon_left}
          iconRight={icon_right}
          iconLeftStyle="w-[100px] lg:w-[129px] xl:w-[158px] 3xl:w-[204px] -mb-0 lg:-mb-3 2xl:-mb-14 3xl:-mb-16"
          iconRightStyle="w-[121px] lg:w-[151px] xl:w-[180px] 3xl:w-[236px]"
        >
          <div className="flex justify-center items-center h-full">
            <div className="md:grid md:gap-x-6 grid-cols-12 text-center">
              <div className="col-span-8 col-start-3 space-y-8 md:space-y-12 xl:space-y-16 2xl:space-y-12 3xl:space-y-16">
                {page_heading && <RichText content={page_heading} />}

                {find_us_placeholder_text && (
                  <VenueIndexSearchBar
                    preSelectedFilters={pre_selected_filter_options}
                    placeholder_text={find_us_placeholder_text}
                    button={[{ link: button_link, label: button_label }]}
                  ></VenueIndexSearchBar>
                )}
              </div>
            </div>
          </div>
        </BackgroundIcons>
      </div>
    </section>
  )
}

export default Hero
