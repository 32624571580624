import classNames from "classnames";
import React, { useEffect, useState } from "react"
import useWindowWidth from "../../../hooks/useWindowWidth";
import Minus from "../../../assets/svgs/Minus.svg";
import Plus from "../../../assets/svgs/Plus.svg";

interface CardProps {
  children: JSX.Element | React.ReactNode;
  heading?: string;
  isExpandable?: boolean;
}

const Card: React.FC<CardProps> = ({ children, heading, isExpandable = false }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const width = useWindowWidth(25);
  const HIDE_AT_THRESHOLD = 671;

  useEffect(() => {
    const IS_HIDDEN = width > HIDE_AT_THRESHOLD;

    setIsOpened(IS_HIDDEN);
  }, [width, HIDE_AT_THRESHOLD]);

  const cardBodyClasses = classNames('w-full bg-White py-6 px-6', {
    'rounded-2xl': !heading || !isExpandable,
    'rounded-b-2xl': heading && isExpandable
  });
  const cardHeaderClasses = classNames('flex justify-between items-center cursor-pointer sm:hidden w-full bg-White py-6', {
    'rounded-2xl': !isOpened,
    'rounded-t-2xl border-b border-b-Body-Disabled border-t-Body-Disabled': isOpened
  })

  return (
    <>
      {heading && (
        <div className={cardHeaderClasses} onClick={() => setIsOpened((prevState: boolean) => !prevState)}>
          <p className='font-semibold pl-6'>{heading}</p>
          {isExpandable && (
            <i className="pr-6">
              {isOpened ? <Minus /> : <Plus />}
            </i>           
          )}
        </div>
      )}

      {(isOpened || !isExpandable) && (
        <div className={cardBodyClasses}>
          {children}
        </div>
      )}
    </>    
  );
};

export default Card;