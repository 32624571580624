import React from "react"
import classNames from "classnames"
import BackgroundIcons from "../../molecules/BackgroundIcons/BackgroundIcons.molecule"
import PrimaryCard from "../../molecules/UpcomingHolidays/PrimaryCard.molecule"
import SecondaryCard from "../../molecules/UpcomingHolidays/SecondaryCard.molecule"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"

// types
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import { BreakpointTypes } from "../../../interfaces/enums/BreakpointTypes.enum"
import { PrimaryCardProps } from "./UpcomingHolidays.interface"
import { SecondaryCardProps } from "./UpcomingHolidays.interface"
import { BorderIconsProps } from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface UpcomingholidaysProps extends BorderIconsProps {
  heading: string
  primary_cards: PrimaryCardProps[]
  secondary_cards: SecondaryCardProps[]
  background_icon: StoryblokAsset
}

const Upcomingholidays: React.FC<UpcomingholidaysProps> = ({
  heading,
  primary_cards,
  secondary_cards,
  background_icon,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const primaryCardHasSibling = primary_cards && primary_cards.length > 1

  const PrimaryCardClassNames = classNames({
    "first:pb-16  first:md:pb-8 first:lg:pb-0 lg:col-span-6 2xl:col-span-5 3xl:col-span-4 first:col-start-1 2xl:first:col-start-2 3xl:first:col-start-3":
      primaryCardHasSibling,
    "col-span-10 col-start-2 3xl:col-span-8 3xl:col-start-3":
      !primaryCardHasSibling,
  })

  const SecondaryCardClassNames = classNames(
    "3xl:col-span-8 3xl:col-start-3 pt-8 md:pt-10 xl:pt-12 space-y-8",
    {
      "lg:col-span-12 lg:col-start-1 2xl:col-span-10 2xl:col-start-2 3xl:col-span-8 3xl:col-start-3":
        primaryCardHasSibling,
      "col-span-10 col-start-2": !primaryCardHasSibling,
    }
  )

  const HeadingClassNames = classNames(
    "text-White text-center text-2xl lg:text-3xl 2xl:text-4xl font-extrabold",
    {
      "pb-20 md:pb-10 lg:pb-20 xl:pb-24 -mb-2": primaryCardHasSibling,
      "pb-[4.5rem] md:pb-8 lg:pb-10 2xl:pb-16": !primaryCardHasSibling,
    }
  )

  return (
    <AnimateInComponent didBecomeVisible={() => {}}>
      <div className="bg-SP6">
        <BorderIconsFactory
          type={BorderIconType.Blok}
          border_icon_top_left={border_icon_top_left}
          border_icon_top_right={border_icon_top_right}
          border_icon_bottom_left={border_icon_top_left}
          border_icon_bottom_right={border_icon_bottom_right}
        >
          <div className="container mx-auto py-16 xl:py-20">
            <BackgroundIcons
              type={
                primaryCardHasSibling
                  ? BackgroundPositionTypes.TopLeftToBottomRight_Outside_Full
                  : BackgroundPositionTypes.TopLeftToBottomRight_OutsideX2
              }
              visibleOn={
                primaryCardHasSibling
                  ? BreakpointTypes.TwoXL
                  : BreakpointTypes.XL
              }
              icon={background_icon}
              mirrorXRight
            >
              <p className={HeadingClassNames}>{heading}</p>
              <div className="lg:grid grid-cols-12 lg:gap-x-6 auto-rows-max">
                {primary_cards &&
                  !!primary_cards.length &&
                  primary_cards.map(
                    ({
                      image,
                      heading,
                      date,
                      body_text,
                      primary_cta,
                      secondary_cta,
                      _uid,
                      border_icon_top_left,
                      border_icon_top_right,
                      border_icon_bottom_left,
                      border_icon_bottom_right,
                    }) => (
                      <div className={PrimaryCardClassNames} key={_uid}>
                        <PrimaryCard
                          image={image}
                          heading={heading}
                          date={date}
                          body_text={body_text}
                          primary_cta={primary_cta}
                          secondary_cta={secondary_cta}
                          hasSiblingCard={primaryCardHasSibling}
                          border_icon_top_left={border_icon_top_left}
                          border_icon_top_right={border_icon_top_right}
                          border_icon_bottom_left={border_icon_bottom_left}
                          border_icon_bottom_right={border_icon_bottom_right}
                        ></PrimaryCard>
                      </div>
                    )
                  )}
                <div className={SecondaryCardClassNames}>
                  {secondary_cards &&
                    !!secondary_cards.length &&
                    secondary_cards.map(
                      (
                        { heading, date, body_text, cta, _uid },
                        index: number
                      ) => (
                        <SecondaryCard
                          key={_uid}
                          heading={heading}
                          date={date}
                          body_text={body_text}
                          cta={cta}
                          index={index}
                        ></SecondaryCard>
                      )
                    )}
                </div>
              </div>
            </BackgroundIcons>
          </div>
        </BorderIconsFactory>
      </div>
    </AnimateInComponent>
  )
}

export default Upcomingholidays
