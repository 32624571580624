module.exports = {
  colors: {
    "Primary-900": "#4b1375",
    "Primary-800": "#671a85",
    "Primary-700": "#771f8d",
    "Primary-600": "#882495",
    "Primary-500": "#95289b",
    "Primary-400": "#a546a9",
    "Primary-300": "#b566b8",
    "Primary-200": "#ca91cd",
    "Primary-100": "#dfbde0",
    "Primary-50": "#f2e4f2",
    "Secondary-300": "#f46a6c",
    "Secondary-200": "#fb9596",
    "Secondary-100": "#ffcbd1",
    "Secondary-50": "#ffeaee",
    SP1: "#ffe600",
    SP2: "#ffa000",
    SP3: "#3DCFFF",
    SP4: "#db3532",
    SP5: "#fd4839",
    SP6: "#7e62bd",
    SP7: "#f2eff6",
    SP8: "#00A9F3",
    Body: "#3d3d3d",
    "Body-Inactive": "#818181",
    "Body-Disabled": "#c9c9c9",
    White: "#ffffff",
    Black: "#000000",
    Green: "#25D816",
    Highlight: "#ffe500",
    Transparent: "rgba(255, 255, 255, 0)",
    Opacity9: "rgba(0, 0, 0, 0.9)",
    // Activity Category Colours
    "Splash-Zone": "#49C9F5",
    "Power-Teams": "#F15846",
    "Super-Sports": "#7C62AB",
    "Globe-Trotters": "#87C870",
    "Creative-Inventors": "#F1696C",
    "Brain-Boosters": "#F99F1B",
    "Zen-Masters": "#FAE80B",
    // Tear Colours
    "Dark-Theme-Tear-Primary": "#FF5E67",
    "Dark-Theme-Tear-Secondary": "#4B1375",
    "Light-Theme-Tear-Primary": "#FFE600",
    "Light-Theme-Tear-Secondary": "#7E62BD",
    "Alternative-Theme-Tear-Primary": "#4B1375",
    "Alternative-Theme-Tear-Secondary": "#F46A6C",
    "White-Theme-Tear-Primary": "#F46A6C",
    "White-Theme-Tear-Secondary": "#4B1375",
    // Hero Gradients
    "Homepage-Hero/Gradient-Start": "#4b1375",
    "Homepage-Hero/Gradient-End": "transparent",
    "Secondary-Hero/Gradient-Start": "#4b1375",
    "Secondary-Hero/Gradient-End": "#7e62bd",
    // Background Texture Colours
    "Background-Texture-Primary": "#FDEBEB",
    "Primary-Button-Text": "#4b1375",
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fontFamily: { default: "Montserrat" },
  boxShadow: {
    Inner: "inset 0px 2px 4px 0px rgba(0,34,66,0.2)",
    Sm: "0px 1px 2px 0px rgba(0,34,66,0.05)",
    Default:
      "0px 1px 2px 0px rgba(0,34,66,0.06), 0px 1px 3px 0px rgba(0,34,66,0.1)",
    Md: "0px 2px 4px -1px rgba(0,34,66,0.06), 0px 4px 6px -1px rgba(0,34,66,0.1)",
    Lg: "0px 4px 6px -2px rgba(0,34,66,0.05), 0px 10px 15px -3px rgba(0,34,66,0.1)",
    Xl: "0px 10px 10px -5px rgba(0,34,66,0.04), 0px 20px 25px -5px rgba(0,34,66,0.1)",
    "2xl": "0px 25px 50px -12px rgba(0,0,0,0.3)",
  },
  borderRadius: {
    none: "0",
    xs: "0.125rem",
    sm: "0.13320313394069672rem",
    default: "0.25rem",
    lg: "0.3125rem",
    xl: "0.375rem",
    "2xl": "0.5rem",
    "3xl": "0.625rem",
    "4xl": "0.6660156846046448rem",
    "5xl": "0.75rem",
    "6xl": "1rem",
    "7xl": "1.25rem",
    "8xl": "1.5rem",
    full: "9999px",
  },
  screens: {
    sm: "672px" /* 640 ith 16px gutter */,
    md: "800px" /* 768 with 16px gutter */,
    lg: "1056px" /* 1024 with 16px gutter */,
    xl: "1440px" /* 1280 with 80px gutter */,
    "2xl": "1600px" /*1440 with 80px gutter */,
    "3xl": "1920px" /* 1536 with 192px gutter */,
  },
  container: {
    padding: {
      DEFAULT: "16px",
      xl: "80px",
      "2xl": "192px",
    },
  },
  extend: {
    transitionDelay: {
      400: "400ms",
      600: "600ms",
      800: "800ms",
      900: "900ms",
      1100: "1100ms",
      1200: "1200ms",
      1300: "1300ms",
    },
  },
  variants: {
    fill: ["group-hover", "hover", "focus"],
    textColor: ["responsive", "hover", "focus", "group-hover"],
    scrollbar: ["rounded"],
    boxShadow: ["responsive", "hover", "focus"],
    extend: {
      gridColumnEnd: ["last"],
    },
  },
  safeList: [
    "text-White",
    "text-Highlight",
    "current",
    "fill-current",
    "rotate-180",
    "group-hover:rotate-180",
    "visible",
    "invisible",
    {
      pattern:
        /text-(Primary|Secondary)-(50|100|200|300|400|500|600|700|800|900)/,
      variants: ["hover", "group-hover"],
    },
    {
      pattern:
        /fill-(Primary|Secondary)-(50|100|200|300|400|500|600|700|800|900)/,
      variants: ["hover", "group-hover"],
    },
  ],
}
