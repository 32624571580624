import React from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"
import CountUp from "react-countup"
import classNames from "classnames"

export interface StatisticBlok extends Blok {
  icon: StoryblokAsset
  figure: number
  sub_text: string
}

export interface StatisticProps {
  statistic: StatisticBlok
  index: number
  isVisible: boolean
}

const Statistic: React.FC<StatisticProps> = ({
  statistic,
  index,
  isVisible,
}) => {
  const ONE_MILLION: number = 1000000
  const ONE_THOUSAND: number = 1000

  const isFigureGreaterThanValue = (value: number): boolean => {
    return statistic.figure >= value
  }

  const getFormattedFigure = (): number => {
    if (isFigureGreaterThanValue(ONE_MILLION)) {
      return statistic.figure / ONE_MILLION
    } else if (isFigureGreaterThanValue(ONE_THOUSAND)) {
      return statistic.figure / ONE_THOUSAND
    } else {
      return statistic.figure
    }
  }

  const getFigurePostfix = (): string => {
    if (isFigureGreaterThanValue(ONE_MILLION)) {
      return "M+"
    } else if (isFigureGreaterThanValue(ONE_THOUSAND)) {
      return "K+"
    } else {
      return "+"
    }
  }

  const statisticFigureClassNames = classNames(
    "font-extrabold text-3xl leading-none transition-all duration-1000 md:text-4xl xl:text-5xl",
    {
      "delay-300": index === 0,
      "delay-400": index === 1,
      "delay-500": index === 2,
      "delay-600": index === 3,
      "opacity-0": !isVisible,
      "opacity-100": isVisible,
    }
  )

  return (
    <li className="col-span-1 flex flex-col items-center text-center space-y-4 lg:space-y-8">
      <div className="w-[100px] aspect-[10/8] xl:w-[120px] 2xl:w-[140px] 3xl:w-[160px]">
        {statistic && statistic.icon && statistic.icon.filename && (
          <Image image={statistic.icon} objectFit={"cover"} />
        )}
      </div>
      <div className="space-y-2">
        {statistic && statistic.figure && (
          <h4 className={statisticFigureClassNames}>
            {isVisible && (
              <>
                <CountUp
                  start={1}
                  end={getFormattedFigure()}
                  duration={3}
                ></CountUp>
                {getFigurePostfix() && <>{getFigurePostfix()}</>}
              </>
            )}
          </h4>
        )}
        {statistic && statistic.sub_text && (
          <p className="leading-snug md:text-lg xl:font-semibold xl:text-xl">
            {statistic.sub_text}
          </p>
        )}
      </div>
    </li>
  )
}

export default Statistic
