import React from "react"
import { BorderIconsProps } from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import {
  valueExists,
  valuesExist,
} from "../../../utils/valueExists/valueExists.util"
import BorderIcon, {
  BorderIconType,
  BorderIconVerticalPosition,
  BorderIconHorizontalPosition,
} from "./BorderIcon.util"

interface BorderIconsFactoryProps extends BorderIconsProps {
  type: BorderIconType
  children: React.ReactElement
}

const BorderIconsFactory: React.FC<BorderIconsFactoryProps> = ({
  border_icon_top_left = undefined,
  border_icon_top_right = undefined,
  border_icon_bottom_left = undefined,
  border_icon_bottom_right = undefined,
  type,
  children,
}) => {
  const hasAnyBorderIcon: boolean = valuesExist([
    border_icon_top_left?.filename,
    border_icon_top_right?.filename,
    border_icon_bottom_left?.filename,
    border_icon_bottom_right?.filename,
  ])

  if (hasAnyBorderIcon) {
    return (
      <div className="w-full h-full relative">
        {/*-- top left border icon --*/}
        {valueExists(border_icon_top_left?.filename) && (
          <BorderIcon
            type={type}
            vertical_position={BorderIconVerticalPosition.Top}
            horizontal_position={BorderIconHorizontalPosition.Leading}
            border_icon={border_icon_top_left!}
          />
        )}

        {/*-- top right border icon --*/}
        {valueExists(border_icon_top_right?.filename) && (
          <BorderIcon
            type={type}
            vertical_position={BorderIconVerticalPosition.Top}
            horizontal_position={BorderIconHorizontalPosition.Trailing}
            border_icon={border_icon_top_right!}
          />
        )}

        {/*-- top left border icon --*/}
        {valueExists(border_icon_bottom_left?.filename) && (
          <BorderIcon
            type={type}
            vertical_position={BorderIconVerticalPosition.Bottom}
            horizontal_position={BorderIconHorizontalPosition.Leading}
            border_icon={border_icon_bottom_left!}
          />
        )}

        {/*-- bottom left border icon --*/}
        {valueExists(border_icon_bottom_right?.filename) && (
          <BorderIcon
            type={type}
            vertical_position={BorderIconVerticalPosition.Bottom}
            horizontal_position={BorderIconHorizontalPosition.Trailing}
            border_icon={border_icon_bottom_right!}
          />
        )}

        {/*-- content --*/}
        <div className="relative">{children}</div>
      </div>
    )
  }

  return <div>{children}</div>
}

export default BorderIconsFactory
