import React, { useContext } from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import BackgroundIcons from "../../molecules/BackgroundIcons/BackgroundIcons.molecule"
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import { BackgroundTextureTypes } from "../../../interfaces/enums/BackgroundTextureTypes.enum"
import BackgroundTexture from "../../molecules/BackgroundTexture/BackgroundTexture.molecule"

interface SectionTitleProps {
  section_title: string
  sub_text: string
  icon: StoryblokAsset
  theme: string
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  section_title,
  sub_text,
  icon,
  theme,
}) => {
  return (
    <section className={`text-Primary-900 overflow-hidden`}>
      <BackgroundTexture
        type={BackgroundTextureTypes.PRIMARY_TEAR}
        theme={theme}
      >
        <div className="container mx-auto py-10 md:pt-16 md:pb-12 2xl:pt-24 2xl:pb-16 text-center">
          <BackgroundIcons
            type={BackgroundPositionTypes.TopLeftToBottomRight_Inside}
            icon={icon}
            className="w-[85px]"
            mirrorXLeft
            mirrorYLeft
          >
            <div className="md:grid md:grid-cols-12 sm:gap-x-6">
              <div className="md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-4">
                {section_title && (
                  <p className="text-xl md:text-2xl xl:text-3xl 3xl:text-4xl font-extrabold">
                    {section_title}
                  </p>
                )}
                {sub_text && (
                  <p className="hidden md:block text-lg 2xl:text-xl pt-4 xl:pt-6">
                    {sub_text}
                  </p>
                )}
              </div>
            </div>
          </BackgroundIcons>
        </div>
      </BackgroundTexture>
    </section>
  )
}

export default SectionTitle
