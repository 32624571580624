import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import Close from "../../../assets/svgs/Close.svg"
import { QueryParams } from "../../../interfaces/enums/QueryParams.enum"
import setQueryParameters from "../../../utils/setQueryParameters.util"
import FilterItem from "./FilterItem.molecule"

export interface FilterItemProps {
  name: string
  icon: string
  websiteFilterItem: string
  cardIcon?: string
  heroIcon?: string
}

interface FilterGroupProps {
  filterItems: FilterItemProps[]
}

interface AgeRangeItem {
  name: string
  range: string
}

interface VenueIndexFilterProps {
  allFilters: FilterGroupProps[]
  activeFilters: string[]
  toggleFilterMenu: (state: boolean) => void
  filterMenu: boolean

  filter_title: string
  clear_all_label: string
}

const VenueIndexFilter: React.FC<VenueIndexFilterProps> = ({
  allFilters,
  activeFilters,
  toggleFilterMenu,
  filterMenu,
  filter_title,
  clear_all_label,
}) => {
  const [filters, setFilters] = useState<any>(activeFilters)
  useEffect(() => {
    setFilters(activeFilters)
  }, [activeFilters])

  const mobile = useMediaQuery({
    query: "(max-width: 1439px)",
  })

  return (
    <>
      <div
        className={`font-montserrat fixed top-0 bottom-0 w-full z-[60] transition-all duration-300 xl:block xl:static xl:transition-none xl:duration-0 ${
          mobile
            ? `max-w-[340px] h-screen ${filterMenu ? "left-0" : "-left-full"}`
            : "pr-14"
        }`}
      >
        <div
          className={`w-full bg-White px-6 xl:px-0 py-8 overflow-y-auto xl:max-w-none xl:p-0 xl:bg-SP7  ${
            mobile ? "h-full" : ""
          }`}
        >
          <div className="flex flex-col items-start pb-2 space-y-6 xl:flex-row xl:items-center xl:justify-between xl:space-y-0">
            <div className="w-full flex items-center justify-between space-x-4">
              <h3 className="font-semibold text-2xl text-Primary-900">
                {filter_title}
              </h3>
              <button
                className="xl:hidden"
                onClick={() => toggleFilterMenu(!filterMenu)}
              >
                <Close className="" />
              </button>
            </div>
            <button
              className="font-bold text-base text-SP4 whitespace-nowrap"
              onClick={() => setQueryParameters([], QueryParams.FILTERS)}
            >
              {clear_all_label}
            </button>
          </div>
          {allFilters.map(
            ({ filterItems }, idx) =>
              filterItems?.length &&
              Boolean(
                filterItems?.filter(item => item.websiteFilterItem).length
              ) && (
                <ul
                  className="py-8 border-b border-b-Body-Disabled space-y-4"
                  key={`FilterList-${idx}`}
                >
                  {filterItems?.map(
                    ({ websiteFilterItem, name, icon }, idx) =>
                      websiteFilterItem && (
                        <FilterItem
                          name={name}
                          icon={icon}
                          setFilters={setFilters}
                          activeFilters={filters}
                          key={`FilterItem-${idx}`}
                        />
                      )
                  )}
                </ul>
              )
          )}
        </div>
      </div>
      <button
        onClick={() => toggleFilterMenu(!filterMenu)}
        className={`fixed top-0 bottom-0 right-0 left-0 z-[50] bg-Black bg-opacity-30 transition-opacity duration-300 xl:hidden xl:transition-none xl:duration-0 cursor-pointer ${
          filterMenu ? "opacity-1" : "opacity-0 pointer-events-none"
        }`}
      ></button>
    </>
  )
}

export default VenueIndexFilter
