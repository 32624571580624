import React, { useState } from "react"
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import { PreviewCardContent } from "../../../interfaces/Storyblok/PreviewCardContent.interface"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import BackgroundIcons from "../../molecules/BackgroundIcons/BackgroundIcons.molecule"
import RelatedArticleCard from "./RelatedArticleCard.component"
import {
  ExistingArticleCardProps,
  CustomContentArticleCardProps,
} from "./RelatedArticleCard.interface"
import { BreakpointTypes } from "../../../interfaces/enums/BreakpointTypes.enum"
import BlokWithBorderIcons, {
  BorderIconsProps,
} from "../../../interfaces/Storyblok/BlokWithBorderIcons.interface"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

interface RelatedArticlesProps extends BlokWithBorderIcons {
  heading_text: string
  article_cards: Array<ExistingArticleCardProps | CustomContentArticleCardProps>
  theme: string
  icon: StoryblokAsset
}

const RelatedArticles: React.FC<RelatedArticlesProps> = ({
  heading_text,
  article_cards,
  theme,
  icon,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const getThemeBackgroundColour = (): string => {
    switch (theme) {
      /*-- maybe we will need this in future? --*/
      default:
        return "bg-SP6"
    }
  }

  const getThemeHeaderTextColour = (): string => {
    switch (theme) {
      /*-- maybe we will need this in future? --*/
      default:
        return "text-White"
    }
  }

  const getArticlePreviewContentFromCard = (
    articleCard: ExistingArticleCardProps | CustomContentArticleCardProps
  ): PreviewCardContent | undefined => {
    if (articleCard.component === "existing_article_card") {
      const existingArticleCard: ExistingArticleCardProps =
        articleCard as ExistingArticleCardProps
      return existingArticleCard?.existing_article?.content
        ?.preview_card_content?.[0]
    } else {
      const customContentArticleCard: CustomContentArticleCardProps =
        articleCard as CustomContentArticleCardProps
      return customContentArticleCard
    }
  }

  const getArticleCardDate = (
    articleCard: ExistingArticleCardProps | CustomContentArticleCardProps
  ): string | undefined => {
    if (articleCard.component === "existing_article_card") {
      const existingArticleCard: ExistingArticleCardProps =
        articleCard as ExistingArticleCardProps
      return existingArticleCard.existing_article.first_published_at
    } else {
      const customContentArticleCard: CustomContentArticleCardProps =
        articleCard as CustomContentArticleCardProps
      return customContentArticleCard.date
    }
  }

  const getArticleCardFullSlug = (
    articleCard: ExistingArticleCardProps | CustomContentArticleCardProps
  ): string => {
    if (articleCard.component === "existing_article_card") {
      const existingArticleCard: ExistingArticleCardProps =
        articleCard as ExistingArticleCardProps
      return existingArticleCard.existing_article.full_slug
    } else {
      const customContentArticleCard: CustomContentArticleCardProps =
        articleCard as CustomContentArticleCardProps
      return customContentArticleCard.link.cached_url
    }
  }

  const getArticlePreviewCardRequiredBorderIcons = (
    articleCard: ExistingArticleCardProps | CustomContentArticleCardProps
  ): BorderIconsProps => {
    if (articleCard.component === "existing_article_card") {
      const existingArticleCard: ExistingArticleCardProps =
        articleCard as ExistingArticleCardProps
      if (existingArticleCard) {
        return {
          border_icon_top_left: existingArticleCard.border_icon_top_left,
          border_icon_top_right: existingArticleCard.border_icon_top_right,
          border_icon_bottom_left: existingArticleCard.border_icon_bottom_left,
          border_icon_bottom_right:
            existingArticleCard.border_icon_bottom_right,
        }
      } else {
        return noRequiredBorderIcons()
      }
    } else {
      const customContentArticleCard: CustomContentArticleCardProps =
        articleCard as CustomContentArticleCardProps
      if (customContentArticleCard) {
        return {
          border_icon_top_left: customContentArticleCard.border_icon_top_left,
          border_icon_top_right: customContentArticleCard.border_icon_top_right,
          border_icon_bottom_left:
            customContentArticleCard.border_icon_bottom_left,
          border_icon_bottom_right:
            customContentArticleCard.border_icon_bottom_right,
        }
      } else {
        return noRequiredBorderIcons()
      }
    }
  }

  const noRequiredBorderIcons = (): BorderIconsProps => {
    return {
      border_icon_top_left: undefined,
      border_icon_top_right: undefined,
      border_icon_bottom_left: undefined,
      border_icon_bottom_right: undefined,
    }
  }

  return (
    <section className={getThemeBackgroundColour()}>
      <BorderIconsFactory
        type={BorderIconType.Blok}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="container mx-auto py-9">
          <BackgroundIcons
            type={BackgroundPositionTypes.TopLeftToBottomRight_Inside}
            icon={icon}
            className="w-48 -rotate-45"
            visibleOn={BreakpointTypes.MOBILE}
            rotateRight
          >
            <div className="pb-14 grid grid-cols-2 gap-x-6 gap-y-12 md:grid-cols-12 md:gap-y-20 lg:gap-y-16 xl:pb-22 2xl:space-y-4">
              <div className="z-10 col-span-2 md:col-span-8 md:col-start-3 lg:col-span-10 lg:col-start-2 xl:col-span-12 xl:col-start-1 3xl:col-span-10 3xl:col-start-2">
                <h3
                  className={`font-montserrat font-bold text-2xl ${getThemeHeaderTextColour()} text-center md:text-3xl 2xl:text-4xl`}
                >
                  {heading_text}
                </h3>
              </div>
              {!!article_cards.length && (
                <div
                  className={`z-10 col-span-2 grid grid-cols-2 gap-y-8 gap-x-6 md:grid-cols-12 md:col-span-8 md:col-start-3 lg:col-span-10 lg:col-start-2 xl:col-span-12 xl:col-start-1 2xl:gap-y-0 2xl:flex w-full ${
                    article_cards.length === 1
                      ? "2xl:justify-center"
                      : article_cards.length === 2
                      ? "2xl:justify-around"
                      : "2xl:justify-between"
                  }`}
                >
                  {article_cards.map(
                    (
                      articleCard:
                        | ExistingArticleCardProps
                        | CustomContentArticleCardProps,
                      index: number
                    ) =>
                      getArticlePreviewContentFromCard(articleCard) ? (
                        <RelatedArticleCard
                          key={articleCard._uid}
                          articlePreviewContent={
                            getArticlePreviewContentFromCard(articleCard)!
                          }
                          articleCardDate={getArticleCardDate(articleCard)}
                          articleCardFullSlug={getArticleCardFullSlug(
                            articleCard
                          )}
                          index={index}
                          numberOfArticles={article_cards.length}
                          {...getArticlePreviewCardRequiredBorderIcons(
                            articleCard
                          )}
                        />
                      ) : (
                        <></>
                      )
                  )}
                </div>
              )}
            </div>
          </BackgroundIcons>
        </div>
      </BorderIconsFactory>
    </section>
  )
}

export default RelatedArticles
