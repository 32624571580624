import React from "react"
import CustomLink from "../../atoms/CustomLink/CustomLink.atom"
import { LinkItem } from "../../../interfaces/LinkItem.interface"

interface DropdownMenuProps {
  links: LinkItem[]
  ulStyles?: string
  liStyles?: string
  position?: string
  width?: string
  dataLayerEventType: string
  parentLinkLabel: string
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  links,
  ulStyles = "bg-Primary-900",
  liStyles = "text-White hover:text-SP1",
  position = "left-0 top-[99%]",
  width = "min-w-[206px]",
  dataLayerEventType,
  parentLinkLabel,
}) => {
  const handleDataLayerEvent = (label: string, link: string): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "click",
      type: dataLayerEventType,
      button: {
        text: label,
        url: link,
      },
      menu: {
        depth: 2,
        levelOne: parentLinkLabel,
      },
      _clear: true,
    })
  }

  return (
    <ul
      className={`${position} ${width} invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all ease-in-out duration-300 ${ulStyles} absolute z-[51] min-w-[206px] pb-2`}
    >
      {links &&
        !!links.length &&
        links.map(link => (
          <li key={link._uid} className="">
            <CustomLink
              url={link.page_link.cached_url}
              className={`p-4 text-xs xl:text-sm font-bold ${liStyles} block`}
              onClick={() =>
                handleDataLayerEvent(
                  link.display_name,
                  link.page_link.cached_url
                )
              }
            >
              <span className="pr-2">{link.display_name}</span>
            </CustomLink>
          </li>
        ))}
    </ul>
  )
}

export default DropdownMenu
