/*-- npm packages --*/
import classNames from 'classnames';
import React, { useState } from 'react';

/*-- component molecules --*/
import Modal from '../../molecules/Modal/Modal.molecule';
import YoutubeVideoPlaceholder from '../../molecules/YoutubeVideo/YoutubeVideoPlaceholder.molecule';

/*-- component atoms --*/
import Button from '../../atoms/Button/Button.component';
import Image from '../../atoms/Image/Image.atom';
import YoutubeVideo from '../../atoms/YoutubeVideo/YoutubeVideo.atom';

/*-- enums --*/
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import TearHorizontalPosition from '../../../interfaces/enums/TearPositioning/TearHorizontalPosition.enum';
import TearVerticalPosition from '../../../interfaces/enums/TearPositioning/TearVerticalPosition.enum';

/*-- interfaces --*/
import ZigZagContentBlokProps from './ZigZagContent.interface';
import { ButtonProps } from '../../../interfaces/Button.interface';

/*-- utils --*/
import AnimateInComponent from '../../utils/AnimateInComponent/AnimateInComponent.util';
import { getSelectedAssetType } from '../../../utils/getSelectedAssetType.util';

/*-- svgs --*/
import AlternativeThemeBlokTear from '../../../assets/svgs/CornerTears/BlokTears/AlternativeThemeBlokTear.svg';
import LightThemeBlokTear from '../../../assets/svgs/CornerTears/BlokTears/LightThemeBlokTear.svg';
import DarkThemeBlokTear from '../../../assets/svgs/CornerTears/BlokTears/DarkThemeBlokTear.svg';
import WhiteThemeBlokTear from '../../../assets/svgs/CornerTears/BlokTears/WhiteThemeBlokTear.svg';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';

const ZigZagGenerator: React.FC<ZigZagContentBlokProps> = ({
  component,
  media_asset,
  heading,
  body_text,
  cta,
  reversed,
  theme,
  theme_new,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const [didBecomeVisible, setDidBecomeVisible] = useState<boolean>(false);
  const [onVideoPlay, setOnVideoPlay] = useState<boolean>(false);

  const isHeavyContentZigZagBlock: boolean = component === 'zig_zag_content_heavy';

  const isPurpleContentBoxStateRequired: boolean = theme_new === 'WHITE';

  const zigZagGeneratorClassNames = classNames('', {
    'bg-SP6': theme_new === 'LIGHT',
    'bg-Primary-900': theme_new === 'DARK',
    'bg-Secondary-300': theme_new === 'ALTERNATIVE',
    'bg-White': theme_new === 'WHITE',
  });

  const zigZagGeneratorGridContainerClassNames = classNames(
    'container mx-auto grid grid-cols-2 gap-x-6 transition-all duration-500 ease-in-out delay-300 md:grid-cols-12 lg:opacity-100 lg:transition-none lg:grid-rows-1 lg:items-center lg:grid-flow-row-dense md:py-16 xl:py-24',
    {
      'py-10': isHeavyContentZigZagBlock,
      'py-12 lg:py-9': !isHeavyContentZigZagBlock,
      'opacity-0 translate-y-10 md:translate-y-16 lg:translate-y-0': !didBecomeVisible,
      'opacity-100 translate-y-0': didBecomeVisible,
    },
  );

  const zigZagGridImageColumnClassNames = classNames(
    'rounded-t-6xl overflow-hidden bg-Primary-300 col-span-2 aspect-video lg:rounded-b-6xl lg:row-start-1 lg:row-end-1 lg:transition-all lg:duration-500 ease-in-out lg:delay-200',
    {
      'lg:aspect-square md:col-span-10 md:col-start-2 md:col-end-12 lg:col-span-6': isHeavyContentZigZagBlock,
      'lg:col-start-1 lg:col-end-7': isHeavyContentZigZagBlock && !reversed,
      'lg:col-start-7 lg:col-end-13': isHeavyContentZigZagBlock && reversed,
      'md:col-span-8 md:col-start-3 lg:aspect-[4/3] lg:col-span-5 xl:aspect-video xl:col-span-8 2xl:col-span-7 3xl:col-span-6':
        !isHeavyContentZigZagBlock,
      'lg:col-start-1 lg:col-end-6 xl:col-end-9 2xl:col-end-8 3xl:col-end-7': !isHeavyContentZigZagBlock && !reversed,
      'lg:col-start-8 lg:col-end-13 xl:col-start-5 2xl:col-start-6 3xl:col-start-7':
        !isHeavyContentZigZagBlock && reversed,
      'lg:opacity-0': !didBecomeVisible,
      'lg:-translate-x-20': !didBecomeVisible && !reversed,
      'lg:translate-x-20': !didBecomeVisible && reversed,
      'lg:opacity-100 lg:translate-x-0': didBecomeVisible,
    },
  );

  const { isImageAssetSelected, getImage, getYoutubeVideo } = getSelectedAssetType(media_asset);

  const zigZagGridContentColumnClassNames = classNames(
    'rounded-b-6xl col-span-2 flex flex-col items-start py-10 px-6 drop-shadow-2xl space-y-8 md:px-16 lg:col-span-7 lg:row-start-1 lg:row-end-1 lg:rounded-t-6xl lg:transition-all lg:duration-500 ease-in-out lg:delay-200',
    {
      'bg-SP6 text-White': isPurpleContentBoxStateRequired,
      'bg-White text-Primary-900': !isPurpleContentBoxStateRequired,
      'md:col-span-10 md:col-start-2 md:col-end-12': isHeavyContentZigZagBlock,
      'md:col-span-8 md:col-start-3 md:col-end-11': !isHeavyContentZigZagBlock,
      'lg:col-start-1 lg:col-end-8': reversed,
      'lg:col-start-6 lg:col-end-13': !reversed,
      'lg:opacity-0': !didBecomeVisible,
      'lg:translate-x-20': !didBecomeVisible && !reversed,
      'lg:-translate-x-20': !didBecomeVisible && reversed,
      'lg:opacity-100 lg:translate-x-0': didBecomeVisible,
    },
  );

  const getButtonType = (index: number): ButtonTypes => {
    return index === 0 ? ButtonTypes.PRIMARY : ButtonTypes.TERTIARY;
  };

  return (
    <section className={zigZagGeneratorClassNames}>
      <AnimateInComponent wrapperComponent={'div'} didBecomeVisible={() => setDidBecomeVisible(true)}>
        <BorderIconsFactory
          type={BorderIconType.Blok}
          border_icon_top_left={border_icon_top_left}
          border_icon_top_right={border_icon_top_right}
          border_icon_bottom_left={border_icon_bottom_left}
          border_icon_bottom_right={border_icon_bottom_right}
        >
          <div className={zigZagGeneratorGridContainerClassNames}>
            {/*-- image --*/}
            <div className={zigZagGridImageColumnClassNames}>
              {media_asset && (
                <>
                  {isImageAssetSelected() && <Image image={getImage().image} objectFit={'cover'} />}
                  {!isImageAssetSelected() && getYoutubeVideo()?.placeholder_image && (
                    <YoutubeVideoPlaceholder
                      image={getYoutubeVideo().placeholder_image}
                      toggleVideo={setOnVideoPlay}
                      positionedAbsolute
                    ></YoutubeVideoPlaceholder>
                  )}
                </>
              )}
            </div>

            {/*-- content --*/}
            <div className={zigZagGridContentColumnClassNames}>
              <div className="space-y-4">
                {heading && <h3 className="font-montserrat font-bold text-lg md:text-xl 2xl:text-3xl">{heading}</h3>}

                {body_text && <p>{body_text}</p>}
              </div>

              {cta && !!cta.length && (
                <div className="w-full flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                  {cta.map((cta_item: ButtonProps, index: number) => (
                    <Button
                      key={`${cta_item.label}-${index + 1}`}
                      link={cta_item.link}
                      label={cta_item.label}
                      type={getButtonType(index)}
                      size={ButtonSizeTypes.LG}
                      className={`
                        w-full lg:w-fit
                        
                      `}
                      wrap={true}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </BorderIconsFactory>
      </AnimateInComponent>
      {!isImageAssetSelected() && getYoutubeVideo()?.video_id && (
        <Modal toggleModal={onVideoPlay} onClose={setOnVideoPlay}>
          <div className="aspect-video w-full max-h-full">
            <YoutubeVideo
              video_id={getYoutubeVideo().video_id}
              videoIsPlaying={onVideoPlay}
              loop={false}
            ></YoutubeVideo>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default ZigZagGenerator;
