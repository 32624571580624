import React from 'react';
import Phone from '../../../assets/svgs/Phone.svg';
import Location from '../../../assets/svgs/Location.svg';
import Button from '../../atoms/Button/Button.component';
import { ButtonProps } from '../../../interfaces/Button.interface';
import { ButtonSizeTypes } from '../../../interfaces/enums/ButtonSizeTypes.enum';
import { ButtonTypes } from '../../../interfaces/enums/ButtonTypes.enum';
import Image from '../../atoms/Image/Image.atom';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import { StoryblokLink } from '../../../interfaces/Storyblok/StoryblokLink.interface';
import { PartnerIconTypes } from '../../../interfaces/Airtable/VenueProps.interface';
// SVG
import SCH from '../../../assets/svgs/SCH.svg';
import Stadium from '../../../assets/svgs/Stadium.svg';

// Types
import { YoutubeVideoBlok } from '../../../interfaces/MediaAsset/YoutubeVideoBlok.interface';
import YoutubeVideo from '../../atoms/YoutubeVideo/YoutubeVideo.atom';
import ImageBlok from '../../../interfaces/MediaAsset/ImageBlok.interface';
import { getSelectedAssetType } from '../../../utils/getSelectedAssetType.util';
import Email from '../../../assets/svgs/Email.svg';

interface SecondaryHeroProps {
  media_asset?: ImageBlok[] | YoutubeVideoBlok[];
  hero_image: StoryblokAsset;
  name: string;
  body?: string;
  contact_number?: string;
  address?: string;
  email?: string;
  cta_link?: string | StoryblokLink;
  cta_label?: string;
  cta?: ButtonProps[];
  icon?: string;
  background_icon?: StoryblokAsset;
  display_contact_number?: boolean;
  display_address: boolean;
  display_email: boolean;
  secondary_cta: ButtonProps[];
}

const SecondaryHero: React.FC<SecondaryHeroProps> = ({
  media_asset,
  hero_image,
  name,
  body,
  contact_number,
  email,
  address,
  cta_link,
  cta_label,
  cta,
  icon,
  background_icon,
  display_contact_number,
  display_address,
  display_email,
  secondary_cta,
}) => {
  const isCTAPopulated = (cta): boolean =>
    Array.isArray(cta) && cta.length > 0 && cta[0].label != undefined && cta[0].link.cached_url != undefined;

  const { isImageAssetSelected, getImage, getYoutubeVideo } = getSelectedAssetType(media_asset);
  const showEmail = email && display_email;
  return (
    <div
      className={`relative overflow-hidden bg-gradient-to-br from-Secondary-Hero/Gradient-Start to-Secondary-Hero/Gradient-End`}
    >
      <div className="container mx-auto h-full py-12 md:py-16 xl:pb-16 xl:pt-24 grid grid-cols-12 lg:gap-x-6 gap-y-10 lg:gap-y-0 relative z-10">
        <div className="order-2 lg:order-1 col-span-12 lg:col-span-6">
          {name && (
            <h1 className="text-2xl md:text-3xl xl:text-4xl 3xl:text-5xl font-extrabold text-White pb-6 2xl:pb-3">
              {name}
            </h1>
          )}
          <div
            className={`${
              showEmail
                ? '3xl:flex 3xl:space-y-0 3xl:space-x-4 3xl:pb-12'
                : '2xl:flex 2xl:space-y-0 2xl:space-x-4 2xl:pb-12'
            } items-start space-y-3 pb-10`}
          >
            {contact_number && display_contact_number && (
              <div className="flex items-start space-x-2">
                <Phone className="text-Secondary-300 -scale-x-100 h-[18px] w-[18px] translate-y-1"></Phone>
                <p className="text-base lg:text-lg font-semibold text-White whitespace-nowrap hidden sm:block">
                  {contact_number}
                </p>
                <a
                  href={`tel:${contact_number}`}
                  className="text-base lg:text-lg font-semibold text-White whitespace-nowrap block sm:hidden"
                >
                  {contact_number}
                </a>
              </div>
            )}
            {address && display_address && (
              <div className="flex items-start space-x-2">
                <Location className="text-Secondary-300 -scale-x-100 h-[18px] w-[18px] translate-y-1"></Location>
                <p className="text-base lg:text-lg font-semibold text-White">{address}</p>
              </div>
            )}
            {email && display_email && (
              <a href={`mailto:${email}`} className="flex items-start space-x-2">
                <Email className="text-Secondary-300 -scale-x-100 h-[18px] w-[18px] translate-y-1"></Email>
                <p className="text-base lg:text-lg font-semibold text-White">{email}</p>
              </a>
            )}
            {body && (
              <div className="">
                <p className="text-base lg:text-lg font-semibold text-White">{body}</p>
              </div>
            )}
          </div>
          <div className="md:flex space-x-0 md:space-x-4 space-y-4 md:space-y-0">
            {cta_label && cta_link && (
              <Button
                link={cta_link}
                label={cta_label}
                type={ButtonTypes.PRIMARY}
                size={ButtonSizeTypes.XL}
                className="!block lg:!inline-block"
              ></Button>
            )}
            {cta && isCTAPopulated(cta) && (
              <Button link={cta[0].link} label={cta[0].label} type={ButtonTypes.PRIMARY} size={ButtonSizeTypes.XL} />
            )}
            {secondary_cta && isCTAPopulated(secondary_cta) && (
              <Button
                link={secondary_cta[0].link}
                label={secondary_cta[0].label}
                type={ButtonTypes.SECONDARY}
                size={ButtonSizeTypes.XL}
                className="!block lg:!inline-block"
              />
            )}
          </div>
        </div>
        <div className="order-1 lg:order-2 col-span-12 lg:col-span-6 lg:col-start-7 3xl:col-span-5 3xl:col-start-8 overflow-hidden">
          <div className="rounded-7xl overflow-hidden aspect-video">
            {media_asset && (
              <>
                {isImageAssetSelected() && <Image image={getImage().image} objectFit={'cover'} />}
                {!isImageAssetSelected() && getYoutubeVideo()?.video_id && (
                  <YoutubeVideo video_id={getYoutubeVideo().video_id} autoplay />
                )}
              </>
            )}
            {hero_image && <Image image={hero_image} objectFit={'cover'} />}
          </div>
        </div>
      </div>
      {icon && (
        <div className="absolute bottom-0 right-0 md:right-12 lg:right-32 xl:right-80 3xl:right-96 left-0 m-auto w-[204px] xl:w-[250px] z-0">
          {/* {icon === PartnerIconTypes.SCH ? <SCH /> : <Stadium />} */}
          <Image image={{ filename: icon }} objectFit="cover" />
          {/* <img src={icon} /> */}
        </div>
      )}
      {background_icon && (
        <div className="absolute bottom-0 right-0 md:right-12 lg:right-32 xl:right-80 3xl:right-96 left-0 m-auto w-[204px] xl:w-[250px] z-0">
          <Image image={background_icon} objectFit={'cover'} />
        </div>
      )}
    </div>
  );
};

export default SecondaryHero;
