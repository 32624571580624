import React, { useEffect, useState } from "react"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import Image from "../../atoms/Image/Image.atom"
import Button from "../../atoms/Button/Button.component"
import { ButtonTypes } from "../../../interfaces/enums/ButtonTypes.enum"
import { ButtonSizeTypes } from "../../../interfaces/enums/ButtonSizeTypes.enum"

export interface SecondaryActionCardItemProps {
  heading: string
  subheading?: string
  icon?: StoryblokAsset
  primaryCta: any
  secondaryCta?: any

  setActiveCard: (state: number) => void
  cardItemOption: number
}

const SecondaryActionCardItem: React.FC<SecondaryActionCardItemProps> = ({
  heading,
  subheading,
  icon,
  primaryCta,
  secondaryCta,

  setActiveCard,
  cardItemOption,
}) => {
  // className={`rounded-[16px] flex justify-center flex-wrap py-10 cursor-pointer hover:bg-SP3 hover:shadow-[0_8px_30px_rgb(0,0,0,0.13)] transition-all duration-[300ms] ${
  //     selectedOption === cardItemOption ? "bg-SP3" : "bg-White"
  //   }`}
  return (
    <div
      className={`rounded-[16px] py-8 md:py-10 px-4 md:px-8 bg-White h-full transition-all duration-[300ms]`}
    >
      <p
        className={`text-center text-xl md:text-2xl font-extrabold w-full pb-2 md:pb-4 text-Body`}
      >
        {heading}
      </p>
      {subheading && (
        <p
          className={`text-center text-base w-full pb-8 md:pb-10 text-Black leading-[1.5rem]`}
        >
          {subheading}
        </p>
      )}
      {icon && (
        <div className="w-[90px] h-[90px] mx-auto mb-3 md:mb-4">
          <Image image={icon} />
        </div>
      )}
      <div className="flex justify-center">
        {primaryCta &&
          !!primaryCta.length &&
          primaryCta[0].label &&
          primaryCta[0].link && (
            <Button
              link={primaryCta[0].link}
              label={primaryCta[0].label}
              type={ButtonTypes.PRIMARY}
              size={ButtonSizeTypes.XL}
              className={`${
                cardItemOption === 2
                  ? `bg-Secondary-100 hover:bg-Secondary-200`
                  : ""
              }`}
            />
          )}
      </div>
      <div className="w-full text-center">
        {secondaryCta &&
          !!secondaryCta.length &&
          secondaryCta[0].label &&
          secondaryCta[0].link && (
            <Button
              link={secondaryCta[0].link}
              label={secondaryCta[0].label}
              type={ButtonTypes.SECONDARY}
              size={ButtonSizeTypes.XL}
              className={`text-Primary-900`}
            />
          )}
      </div>
    </div>
  )
}

export default SecondaryActionCardItem
