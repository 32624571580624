import React, { useState, useContext, useEffect } from "react"
import Blok from "../../../interfaces/Storyblok/Blok.interface"
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api"
import { StoryblokAsset } from "../../../interfaces/Storyblok/StoryblokAsset.interface"
import FindUsPageVenueCardConfigProps from "../../../interfaces/FindUsPage/FindUsPageVenueCardConfig.interface"
import HeroBannerSearchBarConfigProps from "../../../interfaces/HeroBannerSearchBarConfig.interface"
import FindUsPageContext from "../../../contexts/FindUsPage.context"
import Venue from "../../../interfaces/Airtable/Venue.interface"
import FindUsHeroSearchBar from "./FindUsHeroSearchBar.component"
import FindUsResultsListCard, {
  FindUsResultsCardType,
} from "../FindUsFilterAndResults/FindUsResults/FindUsResultsListCard.component"

interface FindUsHeroMapConfigProps extends Blok {
  api_key: string
  title_label: string
  default_icon: StoryblokAsset
  selected_icon: StoryblokAsset
  card_config: FindUsPageVenueCardConfigProps[]
}

interface FindUsHeroProps extends Blok {
  map_config: FindUsHeroMapConfigProps[]
  search_bar_config: HeroBannerSearchBarConfigProps[]
}

const FindUsHero: React.FC<FindUsHeroProps> = ({
  map_config,
  search_bar_config,
}) => {
  const { filteredVenues, selectedMapVenue, updateSelectedMapVenue } =
    useContext(FindUsPageContext)

  const CENTRE_OF_UK_LAT_LNG = { lat: 52.3342942, lng: -1.2753474 }

  const getAllFilteredVenues = (): Venue[] => {
    return filteredVenues.flatMap(filteredVenue => filteredVenue)
  }

  let mapRef: GoogleMap

  const [isMapRefInitialized, setIsMapRefInitialized] = useState<boolean>(false)

  const getMapStyles = () => {
    return [
      {
        featureType: "all",
        elementType: "labels",
        stylers: [
          {
            hue: "#7B00FF",
          },
          {
            saturation: "28",
          },
          {
            lightness: "17",
          },
          {
            gamma: "0.87",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            saturation: -11,
          },
          {
            color: "#D4D7F1",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            saturation: -78,
          },
          {
            hue: "#6600FF",
          },
          {
            lightness: -47,
          },
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: -79,
          },
          {
            color: "#866CD1",
          },
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [
          {
            lightness: 30,
          },
          {
            weight: 1.3,
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
          {
            hue: "#5E00FF",
          },
          {
            saturation: -16,
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "all",
        stylers: [
          {
            saturation: -72,
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            saturation: "1",
          },
          {
            lightness: "5",
          },
          {
            gamma: "10.00",
          },
          {
            color: "#9986D8",
          },
        ],
      },
    ]
  }

  const getMapConfig = (): FindUsHeroMapConfigProps | undefined => {
    return map_config?.length > 0 ? map_config[0] : undefined
  }

  const getMarkerIcon = (venue: Venue): string | undefined => {
    return getMapConfig()
      ? venue === selectedMapVenue
        ? getMapConfig()?.selected_icon.filename
        : getMapConfig()?.default_icon.filename
      : undefined
  }

  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -height - 60,
  })

  const handleMapVenueSelectedAction = (filteredVenue: Venue): void => {
    updateSelectedMapVenue(
      selectedMapVenue === filteredVenue ? undefined : filteredVenue
    )
  }

  useEffect(() => {
    if (selectedMapVenue) {
      panToSelectedMapVenue()
    }
  }, [selectedMapVenue])

  const panToSelectedMapVenue = (): void => {
    if (selectedMapVenue) {
      const coordinatesToPanTo = {
        lat: selectedMapVenue!.getCoordinates()!.latitude!,
        lng: selectedMapVenue!.getCoordinates()!.longitude!,
      }
      mapRef.state.map?.panTo(coordinatesToPanTo)
      mapRef.state.map?.panBy(0, -180)
    }
  }

  useEffect(() => {
    updateMapBounds()
  }, [isMapRefInitialized])

  useEffect(() => {
    updateMapBounds()
  }, [filteredVenues])

  const updateMapBounds = (): void => {
    if (mapRef) {
      const bounds = new window.google.maps.LatLngBounds()

      const allFilteredVenuesWithCoordinates: Venue[] =
        getAllFilteredVenues().filter((venue: Venue) => venue.hasCoordinates())

      if (allFilteredVenuesWithCoordinates.length > 0) {
        allFilteredVenuesWithCoordinates.map((venue: Venue) => {
          const coordinatesToAddToBounds = {
            lat: venue!.getCoordinates()!.latitude!,
            lng: venue!.getCoordinates()!.longitude!,
          }
          bounds.extend(new window.google.maps.LatLng(coordinatesToAddToBounds))
        })
        mapRef?.state.map?.fitBounds(bounds)
      }
    }
  }

  return (
    <section className="relative w-screen h-[336px] lg:h-[400px] xl:h-[500px] 2xl:h-[600px] 3xl:h-[740px]">
      {/*-- google maps --*/}
      <LoadScript googleMapsApiKey={map_config[0].api_key}>
        <GoogleMap
          mapContainerClassName="w-full h-full"
          ref={(ref: GoogleMap) => {
            if (!mapRef) {
              mapRef = ref
              setIsMapRefInitialized(true)
            }
          }}
          center={CENTRE_OF_UK_LAT_LNG}
          options={{
            styles: getMapStyles(),
            zoomControl: true,
            maxZoom: 14,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
        >
          <div className="hidden md:block">
            {getAllFilteredVenues().map(
              (filteredVenue: Venue, index: number) => {
                return (
                  filteredVenue.getCoordinates() && (
                    <Marker
                      key={filteredVenue.rawVenueData.Site_Code ?? index}
                      position={{
                        lat: filteredVenue.getCoordinates()!.latitude,
                        lng: filteredVenue.getCoordinates()!.longitude,
                      }}
                      icon={getMarkerIcon(filteredVenue)}
                      onClick={() =>
                        handleMapVenueSelectedAction(filteredVenue)
                      }
                    />
                  )
                )
              }
            )}

            {selectedMapVenue && (
              <OverlayView
                position={{
                  lat: selectedMapVenue!.getCoordinates()!.latitude!,
                  lng: selectedMapVenue!.getCoordinates()!.longitude!,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getPixelPositionOffset}
              >
                <FindUsResultsListCard
                  venue={selectedMapVenue}
                  card_config={getMapConfig()?.card_config}
                  type={FindUsResultsCardType.MAP}
                />
              </OverlayView>
            )}
          </div>
        </GoogleMap>
      </LoadScript>

      {/*-- search bar --*/}
      <div className="absolute top-0 right-0 bottom-0 left-0 pointer-events-none">
        <div className="h-full container mx-auto grid grid-cols-2 grid-rows-2 gap-x-6 justify-center md:grid-cols-12 md:grid-rows-1">
          <div className="col-span-2 flex items-center justify-center md:col-span-12 md:items-start md:justify-start md:pt-14 2xl:pt-20">
            <div className="rounded-6xl bg-Primary-900 p-4 w-fit font-montserrat font-extrabold text-White text-2xl md:px-9 md:py-6 lg:text-3xl xl:text-4xl 2xl:text-5xl">
              <h1>Find a club</h1>
            </div>
          </div>
          <div className="col-span-2 pointer-events-auto md:col-span-8 md:col-start-3 md:relative md:top-7 lg:top-8">
            <FindUsHeroSearchBar search_bar_config={search_bar_config} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FindUsHero
