import React from "react"
import classNames from "classnames"
import BackgroundIcons from "../../molecules/BackgroundIcons/BackgroundIcons.molecule"
import ServiceCard from "../../molecules/ServiceList/ServiceCard.molecule"
import AnimateInComponent from "../../utils/AnimateInComponent/AnimateInComponent.util"

// types
import { BackgroundPositionTypes } from "../../../interfaces/enums/BackgroundPositionTypes.enum"
import { BreakpointTypes } from "../../../interfaces/enums/BreakpointTypes.enum"
import { ClubTypes } from "../../../interfaces/Airtable/VenueProps.interface"
import { ServiceListProps } from "../../../interfaces/VenuePage/Services.interface"

// SVG
import BackgroundSquiggle from "../../../assets/svgs/BackgroundSquiggle.svg"
import BorderIconsFactory from "../../utils/BorderIconsFactory/BorderIconsFactory.util"
import { BorderIconType } from "../../utils/BorderIconsFactory/BorderIcon.util"

const ServiceList: React.FC<ServiceListProps> = ({
  service_cards,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  const PrimaryCardClassNames = classNames(
    "col-start-1 col-span-12 xl:col-span-10 xl:col-start-2 3xl:col-span-8 3xl:col-start-3"
  )

  return (
    <AnimateInComponent didBecomeVisible={() => {}}>
      <div className="bg-White">
        <BorderIconsFactory
          type={BorderIconType.Blok}
          border_icon_top_left={border_icon_top_left}
          border_icon_top_right={border_icon_top_right}
          border_icon_bottom_left={border_icon_bottom_left}
          border_icon_bottom_right={border_icon_bottom_right}
        >
          <div className="container mx-auto py-16 xl:py-20">
            <BackgroundIcons
              type={BackgroundPositionTypes.BottomLeftToTopRight_OutsideX2}
              visibleOn={BreakpointTypes.ThreeXL}
              svg={<BackgroundSquiggle></BackgroundSquiggle>}
              mirrorXLeft
              mirrorYLeft
            >
              <div className="lg:grid grid-cols-12 lg:gap-x-6 auto-rows-max space-y-8">
                {service_cards &&
                  !!service_cards.length &&
                  service_cards.map(
                    (
                      {
                        image,
                        icon,
                        heading = ClubTypes.Holiday_Clubs,
                        body = "",
                        primary_cta,
                        secondary_cta,
                        border_icon_top_left,
                        border_icon_top_right,
                        border_icon_bottom_left,
                        border_icon_bottom_right,
                      },
                      idx
                    ) => (
                      <div
                        className={PrimaryCardClassNames}
                        key={`service-item-${idx}`}
                      >
                        <ServiceCard
                          icon={icon}
                          image={image}
                          heading={heading}
                          body={body}
                          primary_cta={primary_cta}
                          secondary_cta={secondary_cta}
                          theme="LIGHT"
                          border_icon_top_left={border_icon_top_left}
                          border_icon_top_right={border_icon_top_right}
                          border_icon_bottom_left={border_icon_bottom_left}
                          border_icon_bottom_right={border_icon_bottom_right}
                        ></ServiceCard>
                      </div>
                    )
                  )}
              </div>
            </BackgroundIcons>
          </div>
        </BorderIconsFactory>
      </div>
    </AnimateInComponent>
  )
}

export default ServiceList
